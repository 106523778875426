import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
  background: #203d4cf2;
  height: 108px;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #db333f;
`;

/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useState } from 'react';

import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import VerifiedIcon from '@mui/icons-material/Verified';

import { Avatar, Badge, Switch } from '@mui/material';

import { StyledItem } from './styles';
import { formatShortDate } from '../../../../utils/date';

interface AwardItemProps {
  item: any;
  color: string;
  backgroundTitle?: string;
  isDash: boolean;
  hasAbsolute?: boolean;
  verifyAthlete: (athlete: any) => void;
  deliveryAward: (athlete: any) => void;
  subscribeAthleteAbsolute: (athlete: any) => void;
}

const AwardItem: React.FC<AwardItemProps> = ({
  color,
  item,
  backgroundTitle,
  isDash,
  hasAbsolute,
  verifyAthlete,
  deliveryAward,
  subscribeAthleteAbsolute,
}): ReactElement => {
  const [rerender, setRerender] = useState(true);

  const [checkedAthleteVerified, setCheckedAthleteVerified] = useState(
    item?.athleteVerified
  );

  const [checkedAwardDeliveried, setCheckedAwardDeliveried] = useState(
    item?.awardDeliveried
  );

  const [checkedAthleteAbsolute, setCheckedAthleteAbsolute] = useState(
    item?.athleteAbsoluteSubscribed
  );

  useEffect(() => {
    if (item) {
      setRerender(false);
      setCheckedAthleteVerified(item?.athleteVerified);
      setCheckedAwardDeliveried(item?.awardDelivered);
      setCheckedAthleteAbsolute(item?.athleteAbsoluteSubscribed);

      setTimeout(() => {
        setRerender(true);
      }, 0);
    }
  }, [item]);

  return (
    <StyledItem
      style={{
        borderLeft: '5px solid transparent',
        borderColor: item?.athleteVerified ? 'green' : 'transparent',
      }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          item?.athleteVerified ? (
            <VerifiedIcon
              style={{ width: 24, height: 24, marginTop: 3, marginLeft: 3 }}
              htmlColor="green"
            />
          ) : undefined
        }
      >
        <Avatar
          alt={item?.nameAthlete}
          src={item?.photo}
          sx={{ width: 64, height: 64 }}
          style={{
            border: '2px solid #000',
          }}
        />
      </Badge>
      <div style={{ flex: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1 }}>
            <div style={{ background: backgroundTitle }}>
              {item?.nameAthlete}
            </div>
            <div style={{ color: '#777', fontSize: 14 }}>
              {item?.gym || '--'}
            </div>
          </div>
          <WorkspacePremiumIcon
            style={{ width: 48, height: 48, fontSize: 48 }}
            htmlColor={color}
          />
        </div>

        {isDash && (
          <div style={{ minHeight: 50 }}>
            {!item?.athleteVerified && (
              <div
                style={{
                  color: '#000',
                  paddingTop: 12,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <React.Fragment>
                  <div>Atleta verificado?</div>{' '}
                  <Switch
                    onClick={() => {
                      verifyAthlete(item);
                      setCheckedAthleteVerified(true);
                    }}
                    checked={checkedAthleteVerified}
                  />
                </React.Fragment>
              </div>
            )}

            {item?.athleteVerified && (
              <div>
                <div
                  style={{
                    color: '#000',
                    paddingTop: 12,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ color: item.awardDelivered ? 'green' : '' }}>
                    {item?.awardDelivered
                      ? `Premiação entregue em ${formatShortDate(
                          item?.awardDelivered
                        )}`
                      : 'Premiação entregue?'}
                  </div>

                  <React.Fragment>
                    {rerender && !item?.awardDelivered && (
                      <Switch
                        onClick={() => {
                          deliveryAward(item);
                          setCheckedAwardDeliveried(true);
                        }}
                        color="success"
                        checked={rerender && checkedAwardDeliveried}
                        disabled={item.awardDelivered}
                      />
                    )}
                  </React.Fragment>
                </div>

                {hasAbsolute && (
                  <div
                    style={{
                      color: '#000',
                      marginTop: -5,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>Inscrever no absoluto?</div>

                    <React.Fragment>
                      {rerender && (
                        <Switch
                          onClick={() => {
                            subscribeAthleteAbsolute(item);
                            setCheckedAthleteAbsolute(!checkedAthleteAbsolute);
                          }}
                          color="success"
                          checked={rerender && checkedAthleteAbsolute}
                        />
                      )}
                    </React.Fragment>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </StyledItem>
  );
};

export default AwardItem;

/* eslint-disable no-plusplus */
import React, { ReactElement, useState } from 'react';
import AwardsAreas from './AwardsAreas';

interface ShowAwardsProps {
  categories: any[];
  eventAreas?: any[];
  event: any;
  areas: number;
  numberDays: number;
  verifyAthlete: (athlete: any) => void;
  deliveryAward: (athlete: any) => void;
  subscribeAthleteAbsolute: (athlete: any) => void;
  finishCategoryAward: (uuid: string) => void;
}

const ShowAwards: React.FC<ShowAwardsProps> = ({
  categories,
  eventAreas,
  areas,
  event,
  numberDays,
  verifyAthlete,
  deliveryAward,
  subscribeAthleteAbsolute,
  finishCategoryAward,
}): ReactElement => {
  const [filters] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
  });

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18 }}>Premiação</div>

      <AwardsAreas
        areas={areas}
        categories={categories}
        event={event}
        filters={filters}
        numberDays={numberDays}
        eventAreas={eventAreas}
        verifyAthlete={verifyAthlete}
        deliveryAward={deliveryAward}
        subscribeAthleteAbsolute={subscribeAthleteAbsolute}
        finishCategoryAward={finishCategoryAward}
      />
    </div>
  );
};

export default ShowAwards;

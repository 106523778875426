import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledCategory } from './styles';

interface Props {
  open: boolean;
  categories: any[];
  handleClose: () => void;
  handleConfirm: (selected: string) => void;
}

const ModalAbsolute: React.FC<Props> = ({
  open,
  categories,
  handleClose,
  handleConfirm,
}): React.ReactElement => {
  const [selected, setSelected] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (!open) {
      setError('');
      setSelected('');
    }
  }, [open]);

  const handleSubmit = () => {
    if (!selected) {
      setError('Por favor, selecione uma categoria!');
      return;
    }

    handleConfirm(selected);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Inscrever no absoluto</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div
            dangerouslySetInnerHTML={{
              __html: 'Selecione a categoria para realizar a inscrição',
            }}
          />
        </DialogContentText>

        <div
          style={{
            margin: '20px 0px',
            maxHeight: 400,
            overflowY: 'auto',
            gap: 8,
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #ccc',
            background: '#efefef',
            borderRadius: 4,
          }}
        >
          {categories.map((item, index) => (
            <StyledCategory
              key={index}
              selected={item.codeAbsolute === selected}
              onClick={() => {
                setSelected(item.codeAbsolute);
                setError('');
              }}
            >
              {item.nameAbsolute}
            </StyledCategory>
          ))}
        </div>
        <div style={{ textAlign: 'center', color: 'red', fontSize: 14 }}>
          {error}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: '#222' }}
          onClick={handleClose}
          variant="outlined"
        >
          {'Cancelar'}
        </Button>
        <Button onClick={handleSubmit} autoFocus variant="contained">
          Inscrever
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAbsolute;

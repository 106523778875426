import styled from 'styled-components';

export const StyledRoot = styled('div')({
  position: 'relative',
  marginTop: 20,
  overflowX: 'auto',
  '@media (min-width: 900px) ': {
    marginBottom: 20,
  },
});

import { useRecoilState } from 'recoil';

import loadingAtom from '../atoms/loading';

const useLoading = (): any => {
  const [isLoadingModal, setLoadingModal] = useRecoilState(loadingAtom);

  return { isLoadingModal, setLoadingModal };
};

export default useLoading;

import styled from 'styled-components';

export const StyledCategory = styled('div')<{ selected: boolean }>(
  ({ selected }) => ({
    padding: '16px 12px',
    borderRadius: 4,
    cursor: 'pointer',
    transition: '0.3s',
    background: selected ? '#083061' : undefined,
    color: selected ? '#fff' : undefined,
    '&:hover': {
      backgroundColor: selected ? undefined : '#ddd',
    },
  })
);

/* eslint-disable no-param-reassign */

export const maskCnpj = (v: string): string => {
  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/, '$1.$2');
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
  v = v.replace(/(\d{4})(\d)/, '$1-$2');
  return v.substring(0, 18);
};

export const maskCpf = (v: string): string => {
  v = v.replace(/\D/g, '');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');

  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return v.substring(0, 14);
};

export const maskCnpjOrCpf = (v: string): string => {
  if (!v) {
    return '-';
  }

  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    return maskCpf(v);
  }

  return maskCnpj(v);
};

export const removeMask = (value: string): string => {
  if (!value) {
    return '';
  }
  return value.toString().replace(/\D/g, '');
};

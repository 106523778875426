import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledContentScroll = styled('div')({
  display: 'flex',
  flex: 1,
  marginTop: 12,
  width: '100%',
  overflowX: 'auto',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const StyledItemCategory = styled(Box)({
  width: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 10px',
  boxSizing: 'border-box',
  transition: '0.4s',
  cursor: 'pointer',

  '&:hover': {
    background: '#083061aa',
    color: '#fff',
  },

  '&:active': {
    background: '#083061',
    color: '#fff',
  },
});

import styled from 'styled-components';

export const StyledRoot = styled('div')({
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 0px',
  boxSizing: 'border-box',
  '@media (min-width: 900px)': {
    padding: '32px 32px',
  },
});

/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import FightItem from '../../ShowAreas/AreasFights/FightItem';
import { StyledRoot } from './styles';

interface AreasFightsProps {
  categories: any[];
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  daySelected: string;
  reasonDesclassified?: any[];
}

const AreasFights: React.FC<AreasFightsProps> = ({
  categories,
  eventAreas,
  areas,
  numberDays,
  daySelected,
  reasonDesclassified,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const sortArrayByDate = (arr: any[]): any[] => {
    return arr.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  };

  useEffect(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      const eventsDay = eventAreas?.filter(
        (item) => Number(item.day) === Number(daySelected)
      );

      const newAreas = eventsDay.map((item) => {
        const fights: any[] = [];

        item.categories.forEach((category: any) => {
          category.fights.forEach((fight: any) => {
            if (fight.athletes.length && fight.status !== 'completed') {
              fights.push({
                ...fight,
                category,
              });
            }
          });
        });

        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          name: item.name,
          categories: [],
          fights: sortArrayByDate(fights),
        };
      });

      setAreasObject(newAreas.filter((item) => item?.fights?.length));
    }
  }, [areas, eventAreas, categories, numberDays, daySelected]);

  const mapBackgroundByStatus = (status: any): string => {
    const options: any = {
      ready: '#66bb6b',
      pending: '#9c27b0',
      desclassified: '#e74c3c',
      default: '#999',
    };

    return options[status] || options.default;
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const [containerHeight, setContainerHeight] = useState(0);

  const itemsToShow = useMemo(() => {
    const itemHeight = 175;
    return Math.floor(containerHeight / itemHeight);
  }, [containerHeight]);

  useEffect(() => {
    const getHeightContainer = () => {
      const containerHeight = containerRef.current?.clientHeight || 0;
      setContainerHeight(containerHeight);
    };
    setTimeout(() => {
      getHeightContainer();
    }, 100);
    window.addEventListener('resize', getHeightContainer);
    return () => window.removeEventListener('resize', getHeightContainer);
  }, []);

  function getLimitedItems<T>(array: T[], limit: number): T[] {
    return array.slice(0, limit);
  }

  return (
    <StyledRoot>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flex: 1,
        }}
      >
        <div
          style={{
            width: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            boxSizing: 'border-box',
          }}
        >
          {areasObject?.map((item, index) => (
            <div key={index} style={{ minWidth: 400, width: 400 }}>
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 20,
                }}
              >
                {item.name}
              </div>
              <div
                ref={containerRef}
                style={{
                  borderRadius: 8,
                  width: 400,
                  marginTop: 1,
                  boxSizing: 'border-box',
                  height: 'calc(100vh - 85px)',
                  overflowY: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {getLimitedItems(item?.fights, itemsToShow)?.map(
                  (fight: any) => (
                    <FightItem
                      key={fight.id}
                      fight={fight}
                      reasons={reasonDesclassified}
                      mapBackgroundByStatus={mapBackgroundByStatus}
                    />
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </StyledRoot>
  );
};

export default AreasFights;

/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import SettingsIcon from '@mui/icons-material/Settings';

import FightItem from '../../ShowAreas/AreasFights/FightItem';

interface AreasFightsProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filters: any;
  daySelected: string;
  reasonDesclassified?: any[];
  redirect: (item: any) => void;
  handleCheckin: (athlete: any) => void;
}

const AreasFights: React.FC<AreasFightsProps> = ({
  categories,
  eventAreas,
  areas,
  numberDays,
  daySelected,
  reasonDesclassified,
  filters,
  handleCheckin,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [days, setDays] = useState<number[]>([]);

  useEffect(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      const newDays: number[] = [];

      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);

        const fights: any[] = [];

        item.categories.forEach((category: any) => {
          category.fights.forEach((fight: any) => {
            if (fight.athletes.length) {
              fights.push({
                ...fight,
                category,
              });
            }
          });
        });

        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          name: item.name,
          categories: [],
          fights,
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
    }
  }, [areas, eventAreas, categories, numberDays]);

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array.length) return [];
    return array.sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();

      if (timeA !== timeB) {
        return timeA - timeB;
      }

      return a.order - b.order;
    });
  };

  const mapBackgroundByStatus = (status: any): string => {
    const options: any = {
      ready: '#66bb6b',
      pending: '#9c27b0',
      desclassified: '#e74c3c',
      default: '#999',
    };

    return options[status] || options.default;
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.genderMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (
            filters.category &&
            !item?.category?.categoryName?.includes(filters.category)
          ) {
            avaliable = false;
          }
          if (
            filters.gender &&
            !item?.category?.categoryName?.includes(filters.gender)
          ) {
            avaliable = false;
          }
          if (
            filters.weight &&
            !item?.category?.categoryName?.includes(filters.weight)
          ) {
            avaliable = false;
          }
          if (
            filters.track &&
            !item?.category?.categoryName?.includes(filters.track)
          ) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item?.category?.typeCategory !== filters.typeCategory
          ) {
            avaliable = false;
          }

          if (
            filters.typeCategoryMultiple?.length &&
            !filters.typeCategoryMultiple.includes(item?.category?.typeCategory)
          ) {
            avaliable = false;
          }

          if (
            filters.categoryMultiple?.length &&
            !filters.categoryMultiple.find((category: string) =>
              item?.category?.categoryName?.includes(category)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.genderMultiple?.length &&
            !filters.genderMultiple.find((gender: string) =>
              item?.category?.categoryName?.includes(gender)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.weightMultiple?.length &&
            !filters.weightMultiple.find((weight: string) =>
              item?.category?.categoryName?.includes(weight)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.trackMultiple?.length &&
            !filters.trackMultiple.find((track: string) =>
              item?.category?.categoryName?.includes(track)
            )
          ) {
            avaliable = false;
          }

          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        gap: 12,
      }}
    >
      <div style={{ width: '100%' }}>
        {renderObjectDay.map((day, index) => (
          <React.Fragment key={`${index}-${day}`}>
            <div
              style={{
                width: '100%',
                overflowX: 'auto',
                display: 'flex',
                gap: 18,
              }}
            >
              {filterByDay(areasObject, day).map((item, index) => (
                <div key={index} style={{ minWidth: 400, width: 400 }}>
                  <div>{item.name}</div>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      borderRadius: 8,
                      width: 400,
                      minHeight: 100,
                      marginTop: 12,
                      boxSizing: 'border-box',
                      padding: 16,
                      gap: 8,
                      display: 'flex',
                      flexDirection: 'column',

                      height: 500,
                      overflowY: 'auto',

                      marginBottom: 8,
                    }}
                  >
                    {sortArrayV2(applyFilters(item.fights))?.map(
                      (fight: any) => (
                        <FightItem
                          key={fight.id}
                          fight={fight}
                          reasons={reasonDesclassified}
                          mapBackgroundByStatus={mapBackgroundByStatus}
                          action={
                            fight?.status !== 'completed' || !fight?.startAt
                              ? {
                                  action: (athlete) => handleCheckin(athlete),
                                  icon: <SettingsIcon />,
                                  label: 'Checkin',
                                  isDisabled: (athlete) => !athlete,
                                }
                              : undefined
                          }
                        />
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AreasFights;

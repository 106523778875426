import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Button, CircularProgress, MenuItem, TextField } from '@mui/material';

import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  EventFormProps,
  initialEventFormValues,
  validationSchema,
} from './schema';
import ConfirmModal from '../ConfirmModal';

interface Props {
  isLoading: boolean;
  event: any;
  onSubmit: (values: EventFormProps) => void;
  handleCancel: () => void;
}

const prepareData = (date: string) => {
  return date.split('/').reverse().join('-');
};

const EditEventForm: React.FC<Props> = ({
  isLoading,
  event,
  onSubmit,
  handleCancel,
}): React.ReactElement => {
  const initialValues = useMemo(() => {
    if (event) {
      const startDate = prepareData(event.startDate);
      const endDate = prepareData(event.endDate);
      return { ...event, startDate, endDate };
    }

    return initialEventFormValues;
  }, [event]);

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Editar dados do evento',
    message:
      'Ao alterar o número de áreas ou número de dias de luta, será necessário realizar a configuração na tela de Áreas. Deseja continuar?',
    onConfirm: () => null,
    onCancel: () => {
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const handleSubmit = (values: EventFormProps) => {
    if (
      values.numberDaysFight !== initialValues.numberDaysFight ||
      values.numberAreas !== initialValues.numberAreas
    ) {
      setModalConfirm({
        ...modalConfirm,
        open: true,
        onConfirm: () => {
          setModalConfirm({ ...modalConfirm, open: false });

          onSubmit(values);
        },
      });
    } else {
      onSubmit(values);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <React.Fragment>
      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmColor="#03a9f4"
      />

      <StyledRoot>
        <StyledForm onSubmit={formik.handleSubmit}>
          <StyledContentInputs>
            <TextField
              fullWidth
              id="name"
              disabled
              variant="outlined"
              name="name"
              label="Nome"
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="startDate"
                variant="outlined"
                disabled
                name="startDate"
                type="date"
                label="Data de inicio"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.startDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
              />
              <TextField
                fullWidth
                id="endDate"
                variant="outlined"
                name="endDate"
                type="date"
                disabled
                label="Data de fim"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.endDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
              />
            </StyledContentTextFields>

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="numberDaysFight"
                variant="outlined"
                name="numberDaysFight"
                label="Nº de dias de luta"
                type="number"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.numberDaysFight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.numberDaysFight &&
                  Boolean(formik.errors.numberDaysFight)
                }
                helperText={
                  formik.touched.numberDaysFight &&
                  formik.errors.numberDaysFight
                }
              />
              <TextField
                fullWidth
                id="numberAreas"
                variant="outlined"
                name="numberAreas"
                type="number"
                label="Nº de áreas"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.numberAreas}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.numberAreas &&
                  Boolean(formik.errors.numberAreas)
                }
                helperText={
                  formik.touched.numberAreas && formik.errors.numberAreas
                }
              />
            </StyledContentTextFields>

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="startTimeFight"
                variant="outlined"
                name="startTimeFight"
                label="Horário de inicio das lutas"
                type="time"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.startTimeFight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.startTimeFight &&
                  Boolean(formik.errors.startTimeFight)
                }
                helperText={
                  formik.touched.startTimeFight && formik.errors.startTimeFight
                }
              />
              <TextField
                fullWidth
                id="timeBetweenFight"
                variant="outlined"
                name="timeBetweenFight"
                type="number"
                label="Tempo entre as lutas (em minutos)"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.timeBetweenFight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.timeBetweenFight &&
                  Boolean(formik.errors.timeBetweenFight)
                }
                helperText={
                  formik.touched.timeBetweenFight &&
                  formik.errors.timeBetweenFight
                }
              />
            </StyledContentTextFields>

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="timeBetweenCategory"
                variant="outlined"
                name="timeBetweenCategory"
                label="Tempo entre categorias (em minutos)"
                type="number"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.timeBetweenCategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.timeBetweenCategory &&
                  Boolean(formik.errors.timeBetweenCategory)
                }
                helperText={
                  formik.touched.timeBetweenCategory &&
                  formik.errors.timeBetweenCategory
                }
              />
              <TextField
                fullWidth
                id="timeCategoryOneAthlete"
                variant="outlined"
                name="timeCategoryOneAthlete"
                label="Tempo para categoria com um atleta na chave (em minutos)"
                type="number"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.timeCategoryOneAthlete}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.timeCategoryOneAthlete &&
                  Boolean(formik.errors.timeCategoryOneAthlete)
                }
                helperText={
                  formik.touched.timeCategoryOneAthlete &&
                  formik.errors.timeCategoryOneAthlete
                }
              />
            </StyledContentTextFields>

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="numberThird"
                variant="outlined"
                name="numberThird"
                label="Nº de terceiros"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.numberThird}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.numberThird &&
                  Boolean(formik.errors.numberThird)
                }
                helperText={
                  formik.touched.numberThird && formik.errors.numberThird
                }
                select
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
              </TextField>

              <TextField
                fullWidth
                id="typeKey3"
                variant="outlined"
                name="typeKey3"
                label="Será usada chave de três?"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.typeKey3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.typeKey3 && Boolean(formik.errors.typeKey3)
                }
                helperText={formik.touched.typeKey3 && formik.errors.typeKey3}
                select
              >
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
              </TextField>
            </StyledContentTextFields>

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="hasThirdPlaceFight"
                variant="outlined"
                name="hasThirdPlaceFight"
                label="Disputa de terceiro lugar?"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.hasThirdPlaceFight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.hasThirdPlaceFight &&
                  Boolean(formik.errors.hasThirdPlaceFight)
                }
                helperText={
                  formik.touched.hasThirdPlaceFight &&
                  formik.errors.hasThirdPlaceFight
                }
                select
              >
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
              </TextField>

              <TextField
                fullWidth
                id="athletesByeFowardAuto"
                variant="outlined"
                name="athletesByeFowardAuto"
                label="Atletas bye avança automático?"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.athletesByeFowardAuto}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.athletesByeFowardAuto &&
                  Boolean(formik.errors.athletesByeFowardAuto)
                }
                helperText={
                  formik.touched.athletesByeFowardAuto &&
                  formik.errors.athletesByeFowardAuto
                }
                select
              >
                <MenuItem value={1}>Sim</MenuItem>
                <MenuItem value={0}>Não</MenuItem>
              </TextField>
            </StyledContentTextFields>

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="typeDrawFight"
                variant="outlined"
                name="typeDrawFight"
                label="Tipo de desempate de luta?"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.typeDrawFight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.typeDrawFight &&
                  Boolean(formik.errors.typeDrawFight)
                }
                helperText={
                  formik.touched.typeDrawFight && formik.errors.typeDrawFight
                }
                select
              >
                <MenuItem value="G">Golden Score</MenuItem>
                <MenuItem value="A">Decisão do arbitro</MenuItem>
              </TextField>

              <TextField
                fullWidth
                id="verifyAthleteDataAward"
                variant="outlined"
                name="verifyAthleteDataAward"
                label="Verificar atleta na premiação?"
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.verifyAthleteDataAward}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.verifyAthleteDataAward &&
                  Boolean(formik.errors.verifyAthleteDataAward)
                }
                helperText={
                  formik.touched.verifyAthleteDataAward &&
                  formik.errors.verifyAthleteDataAward
                }
                select
              >
                <MenuItem value={1}>Sim</MenuItem>
                <MenuItem value={0}>Não</MenuItem>
              </TextField>
            </StyledContentTextFields>
          </StyledContentInputs>

          <StyledContentButtons>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              disabled={isLoading}
              style={{
                letterSpacing: 1,
                fontSize: 14,
                background: '#eee',
              }}
              onClick={() => handleCancel()}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
            </Button>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isLoading}
              style={{
                letterSpacing: 1,
                fontSize: 14,
              }}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
            </Button>
          </StyledContentButtons>
        </StyledForm>
      </StyledRoot>
    </React.Fragment>
  );
};

export default EditEventForm;

/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

interface CheckinLegendProps {
  items: {
    label: string;
    color: string;
  }[];
}

const CheckinLegend: React.FC<CheckinLegendProps> = ({
  items,
}): ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 32,
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <div
            style={{
              width: 36,
              height: 36,
              borderRadius: 36,
              background: item.color,
            }}
          />
          <div>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default CheckinLegend;

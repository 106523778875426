import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import {
  changeAthletesRequest,
  changeFightsRequest,
  getEventAreasFights,
  getEventCategories,
  getEventFilters,
  getReasonDesclassifiedRequest,
  saveEventAreas,
} from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import ModalInvalid from './ModalInvalid';
import OrganizeAreas from '../../../components/OrganizeAreas';
import NoAreas from '../../../components/NoAreas';
import ShowAreas from '../../../components/ShowAreas';
import ConfirmModal from '../../../components/ConfirmModal';
import LoadingModal from './LoadingModal';
import { useQuery } from '../../../hooks/useQuery';

const AreasOrganize: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const query = useQuery();
  const isEditingDefault: string | null = query.get('isEditing');

  const { eventId } = useParams();

  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (isEditingDefault) {
      setEditing(true);
      query.delete('isEditing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    confirmLabel: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Atenção',
    confirmLabel: 'Confirmar',
    message:
      'O evento já começou, ao editar as áreas os dados do placar podem sofrer alterações. Deseja continuar?',
    onConfirm: () => {
      setEditing(true);
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const [event, setEvent] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [eventAreas, setEventAreas] = useState<any>([]);

  const [isLoadingSave, setLoadingSave] = useState(false);

  const [filters, setFilters] = useState<any>({});

  const [reasonDesclassified, setReasonDesclassified] = useState<any>([]);

  const [isModalInvalid, setModalInvalid] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAreas, setLoadingAreas] = useState(true);
  const [isLoadingFilters, setLoadingFilters] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
        setCategories(data?.categories || []);

        if (!data?.event?.numberAreas) {
          setModalInvalid(true);
        }
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getReasonDesclassified = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonDesclassifiedRequest(authenticate.token);

        setReasonDesclassified(data?.data || []);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  }, [authenticate.token]);

  useEffect(() => {
    getReasonDesclassified();
  }, [getReasonDesclassified]);

  const getEventAreasFightsData = useCallback(
    async (defaultLoading = true) => {
      try {
        setLoadingAreas(defaultLoading);
        if (authenticate.token && eventId) {
          const data = await getEventAreasFights(authenticate.token, eventId);

          if (data?.areas?.length) {
            setEventAreas(data?.areas || []);
          } else {
            setModalInvalid(true);
          }
        }
      } catch (error) {
        toast(
          'Não foi possível executar essa ação. Por favor, tente novamente!',
          { type: 'error' }
        );
      } finally {
        setLoadingAreas(false);
      }
    },
    [authenticate.token, eventId]
  );

  useEffect(() => {
    getEventAreasFightsData();
  }, [getEventAreasFightsData]);

  const getFilters = useCallback(async () => {
    try {
      setLoadingFilters(true);
      if (authenticate.token && eventId) {
        const data = await getEventFilters(authenticate.token, eventId);
        setFilters(data?.filters || {});
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoadingFilters(false);
    }
  }, [authenticate.token, eventId]);

  const handleSave = async (
    data: any,
    generateFights: boolean
  ): Promise<boolean> => {
    setLoadingSave(true);
    try {
      if (authenticate.token && eventId) {
        await saveEventAreas(
          authenticate.token,
          eventId,
          generateFights,
          data.map((item: any, index: number) => {
            return {
              ...item,
              categories: item.categories.map(
                (category: any, indexCategory: number) => {
                  return {
                    ...category,
                    order: indexCategory,
                  };
                }
              ),
              order: index,
            };
          })
        );

        toast('Lutas geradas com sucesso!', { type: 'success' });

        await getEventAreasFightsData(false);
      }
      return true;
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
      return false;
    } finally {
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const isLoadingGeneral = useMemo(() => {
    return isLoading || isLoadingAreas || isLoadingFilters;
  }, [isLoading, isLoadingAreas, isLoadingFilters]);

  const handleStartEditing = () => {
    const hasStarted = eventAreas?.some(
      (item: any) => item.status !== 'pending'
    );

    if (hasStarted) {
      setModalConfirm({
        open: true,
        title: 'Atenção',
        confirmLabel: 'Confirmar',
        message:
          'O evento já começou, ao editar as áreas os dados do placar podem sofrer alterações. Deseja continuar?',
        onConfirm: () => {
          setEditing(true);
          setModalConfirm((prev) => ({
            ...prev,
            open: false,
          }));
        },
        onCancel: () => {
          setModalConfirm((prev) => ({
            ...prev,
            open: false,
          }));
        },
      });
    } else {
      setEditing(true);
    }
  };

  const changeAthletes = async (from: string, to: string): Promise<void> => {
    try {
      if (authenticate.token && eventId) {
        await changeAthletesRequest(authenticate.token, eventId, from, to);

        toast('Atletas alterados com sucesso!', { type: 'success' });

        getEventAreasFightsData(false);
      }
    } catch (erro) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const changeFights = async (from: string, to: string): Promise<void> => {
    try {
      if (authenticate.token && eventId) {
        await changeFightsRequest(authenticate.token, eventId, from, to);

        toast('Lutas alteradas com sucesso!', { type: 'success' });

        getEventAreasFightsData(false);
      }
    } catch (erro) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const mapStage = (stage: any): string => {
    const stageOrder: any = {
      'eighth-final': 'Oitavas de final',
      'quarter-final': 'Quartas de final',
      semifinal: 'Semifinal',
      bronze: 'Disputa de Terceiro',
      final: 'Final',
    };

    return stageOrder[stage] || stage;
  };

  const mapStageName = (fight: any): string => {
    return `${mapStage(fight.stage)} ${
      !['final', 'bronze'].includes(fight.stage) ? fight.order + 1 : ''
    }`;
  };

  const handleChangeFight = (from: any, to: any) => {
    setModalConfirm({
      open: true,
      title: 'Trocar luta',
      confirmLabel: 'Confirmar',
      message: `Deseja trocar de posição a luta <b>${mapStageName(
        from
      )}</b> com a <b>${mapStageName(to)}</b>?`,
      onConfirm: () => {
        changeFights(from.uuid, to.uuid);
        setModalConfirm((prev) => ({
          ...prev,
          open: false,
        }));
      },
      onCancel: () => {
        setModalConfirm((prev) => ({
          ...prev,
          open: false,
        }));
      },
    });
  };

  const handleChangeAthletes = (from: any, to: any) => {
    setModalConfirm({
      open: true,
      title: 'Trocar atletas',
      confirmLabel: 'Confirmar',
      message: `Deseja trocar de posição o atleta <b>${from.nameAthlete}</b> com o <b>${to.nameAthlete}</b>?`,
      onConfirm: () => {
        changeAthletes(from.uuid, to.uuid);
        setModalConfirm((prev) => ({
          ...prev,
          open: false,
        }));
      },
      onCancel: () => {
        setModalConfirm((prev) => ({
          ...prev,
          open: false,
        }));
      },
    });
  };

  const handleRedirectArea = (area: any, item: any) => {
    window.open(
      `/#/dash/events/${eventId}/areas/${area.uuid}/brackets/${item.uuid}`,
      '_blank'
    );
  };

  return (
    <React.Fragment>
      <ModalInvalid
        open={isModalInvalid}
        cause={[]}
        handleClose={() => navigate(`/dash/events/${eventId}/home?edit=true`)}
        message="Alguns campos do evento não estão configurados. Por favor, finaliza a configuração primeiro!"
      />

      <LoadingModal
        open={isLoadingSave}
        message="Aguarde enquanto as lutas estão sendo geradas"
        title="Gerando lutas"
      />
      <StyledRoot>
        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoading}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                    {
                      key: 'numberDaysFight',
                      label: 'Nº de dias',
                    },
                    {
                      key: 'numberAreas',
                      label: 'Nº de áreas',
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
            />

            {!isLoadingGeneral &&
              !!event &&
              !eventAreas?.length &&
              !isEditing && <NoAreas handleStart={() => setEditing(true)} />}

            {!isLoadingGeneral &&
              !!event &&
              !isEditing &&
              !!eventAreas?.length && (
                <ShowAreas
                  categories={categories}
                  eventAreas={eventAreas}
                  filtersData={filters}
                  areas={event?.numberAreas}
                  numberDays={event?.numberDaysFight}
                  startTime={event.startTimeFight}
                  handleStart={handleStartEditing}
                  reasonDesclassified={reasonDesclassified}
                  handleChangeAthletes={handleChangeAthletes}
                  handleChangeFight={handleChangeFight}
                  handleRedirectArea={handleRedirectArea}
                />
              )}

            {!isLoadingGeneral && !!event && isEditing && (
              <OrganizeAreas
                categories={categories}
                handleFinish={() => {
                  setEditing(false);
                  getData();
                  getEventAreasFightsData();
                }}
                eventAreas={eventAreas}
                filtersData={filters}
                areas={event?.numberAreas}
                numberDays={event?.numberDaysFight}
                startTime={event.startTimeFight}
                handleSave={handleSave}
                isLoading={isLoadingSave}
                setModalConfirm={setModalConfirm}
              />
            )}
          </div>
        </Card>
      </StyledRoot>
      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        confirmLabel={modalConfirm.confirmLabel}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmColor="#333"
      />
    </React.Fragment>
  );
};

export default AreasOrganize;

/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Avatar, Badge, Tooltip } from '@mui/material';

interface AthleteItemProps {
  athlete: any;
  color?: string;
  background?: string;
  action?: {
    icon: React.ReactElement;
    action: (athlete: any) => void;
    label: string;
    isDisabled?: (athlete: any) => boolean;
  };
  reasons?: any[];
  draggable?: boolean;
  onDragStart?: (event: React.DragEvent) => void;
  onDragOver?: (event: React.DragEvent) => void;
  onDragLeave?: (event: React.DragEvent) => void;
  onDragEnd?: (event: React.DragEvent) => void;
  onDrop?: (event: React.DragEvent) => void;
}

const AthleteItem: React.FC<AthleteItemProps> = ({
  athlete,
  color = '#000',
  background = '#ddd',
  action,
  reasons,
  draggable,
  onDragStart,
  onDragOver,
  onDragLeave,
  onDragEnd,
  onDrop,
}): ReactElement => {
  const isWinner = (athlete: any): boolean => {
    return athlete?.result?.win;
  };

  const getReasonDesclassification = (code: number): React.ReactElement => {
    const reason = reasons?.find((item) => item.id === code);

    const text = reason?.description || 'Desclassificado';

    return (
      <>
        <b>Atleta desclassificado</b>

        <p>Motivo: {text}</p>
      </>
    );
  };

  return (
    <div
      style={{
        background,
        color,
        padding: 8,
        boxSizing: 'border-box',
        height: 60,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
      }}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
    >
      <div style={{ display: 'flex', gap: 8, width: 'calc(100% - 60px)' }}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            isWinner(athlete) ? (
              <div style={{ position: 'relative' }}>
                <CheckCircleIcon
                  style={{
                    width: 16,
                    height: 16,
                    zIndex: 2,
                    position: 'relative',
                  }}
                  htmlColor="lightgreen"
                />
                <div
                  style={{
                    background: '#2b4755',
                    width: 12,
                    height: 12,
                    position: 'absolute',
                    top: 2,
                    left: 2,
                    zIndex: 1,
                    borderRadius: 100,
                  }}
                ></div>
              </div>
            ) : undefined
          }
        >
          <Avatar
            alt={athlete?.nameAthlete}
            src={athlete?.photo}
            sx={{ width: 36, height: 36 }}
            style={{
              border: isWinner(athlete)
                ? '2px solid lightgreen'
                : '2px solid #000',
            }}
          />
        </Badge>
        <div style={{ flex: 1 }}>
          <div
            style={{
              fontSize: 14,
              width: 220,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {athlete?.nameAthlete || '---'}
          </div>
          <div
            style={{
              fontSize: 10,
              paddingTop: 4,
              width: 220,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {athlete?.gym || '---'}
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          width: 80,
          gap: 4,
          justifyContent: 'flex-end',
        }}
      >
        {athlete?.reasonDesclassificationCode && (
          <Tooltip
            title={getReasonDesclassification(
              athlete?.reasonDesclassificationCode
            )}
            arrow
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        )}
        {action && (
          <Tooltip title={action.label} arrow placement="top">
            <div
              style={{
                padding: 0,
                width: 24,
                height: 24,
                borderRadius: 50,
                cursor: action?.isDisabled?.(athlete) ? undefined : 'pointer',
                pointerEvents: action?.isDisabled?.(athlete)
                  ? 'none'
                  : undefined,
                color: action?.isDisabled?.(athlete) ? '#999' : undefined,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={
                action?.isDisabled?.(athlete)
                  ? undefined
                  : (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      action.action(athlete);
                    }
              }
            >
              {action.icon}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default AthleteItem;

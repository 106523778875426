/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { StyledItemCategory } from './styles';
import {
  differenceInMinutesCeil,
  formatMinutesToTime,
  formatOnlyHour,
} from '../../utils/date';
import LoadingModal from '../../pages/private/AreasOrganize/LoadingModal';
import FilterOrganizeAreaV2 from '../ShowAreas/FilterOrganizeAreaV2';

const CATEGORY_BASE_ID = -1;

interface OrganizeAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  handleSave: (data: any, generateFights: boolean) => Promise<boolean>;
  isLoading: boolean;
  handleFinish: () => void;
  setModalConfirm: (data: any) => void;
}

const defaultFilters = {
  category: '',
  track: '',
  weight: '',
  gender: '',
  typeCategory: '',
  generateKey: 'S',
  typeCategoryMultiple: [] as string[],
  categoryMultiple: [] as string[],
  trackMultiple: [] as string[],
  weightMultiple: [] as string[],
  genderMultiple: [] as string[],
};

const OrganizeAreas: React.FC<OrganizeAreasProps> = ({
  categories,
  eventAreas,
  areas,
  filtersData,
  isLoading,
  handleSave,
  handleFinish,
  setModalConfirm,
}): ReactElement => {
  const [items, setItems] = useState<any[]>([]);

  const [itemsAutoOrganize, setItemsAutoOrganize] = useState<any[]>([]);

  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [updatedAt, setUpdateAt] = useState<Date | undefined>(undefined);

  const [isUpdating, setUpdating] = useState(false);

  const [daySelected, setDaySelected] = useState('0');

  const [days, setDays] = useState<number[]>([]);

  const [itemsSelecteds, setItemsSelecteds] = useState<any[]>([]);

  const [changes, setChanges] = useState<number[]>([]);

  const [filters, setFilters] = useState(defaultFilters);

  const [dragAreaHover, setDragAreaHover] = useState<number | null>(null);

  const [dragCategoryHover, setDragCategoryHover] = useState<number | null>(
    null
  );

  const [isAutoOrganize, setAutoOrganize] = useState(false);

  const [isLoadingAutoOrganize, setIsLoadingAutoOrganize] = useState(false);

  const handleSubmit = async (data: any) => {
    try {
      setUpdating(true);
      setUpdateAt(undefined);

      if (changes.length) {
        const dataToSubmit = data.filter((item: any) =>
          changes.includes(item.id)
        );

        const success = await handleSave(dataToSubmit, true);

        if (success) {
          setChanges([]);
        }
      }
    } catch {
      // eslint-disable-next-line no-console
      console.log('erro');
    } finally {
      setUpdateAt(new Date());
      setUpdating(false);
    }
  };

  const prepareData = useCallback(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      let newItems = [...categories];
      const newDays: number[] = [];
      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);
        return {
          id: item.id,
          day: item.day,
          uuid: item.uuid,
          name: item.name,
          categories: item.categories?.length
            ? item.categories
                .map((category: any) => {
                  const categoryData = categories.find((itemCategory) => {
                    return itemCategory.categoryId === category.category;
                  });

                  newItems = newItems.filter(
                    (item) =>
                      item.categoryId !== category.category ||
                      item.generateKey === 'N'
                  );

                  return {
                    ...category,
                    ...categoryData,
                    order: category.order,
                  };
                })
                .sort((a: any, b: any) => {
                  return a.order - b.order;
                })
            : [],
        };
      });

      const daysToSet = [...new Set(newDays)].sort();

      setDays(daysToSet);

      const newItemsAutoOrganize = [];

      for (let i = 0; i < daysToSet.length; i++) {
        newItemsAutoOrganize[i] = [];
      }

      setItemsAutoOrganize(newItemsAutoOrganize);

      setAreasObject(newAreas);
      setItems(newItems);
    }
  }, [areas, categories, eventAreas]);

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  const initItemsAutoOrganize = useCallback(() => {
    const newItemsAutoOrganize = [];

    for (let i = 0; i < days.length; i++) {
      newItemsAutoOrganize[i] = [];
    }

    setItemsAutoOrganize(newItemsAutoOrganize);
  }, [days]);

  useEffect(() => {
    initItemsAutoOrganize();
  }, [initItemsAutoOrganize]);

  const handleSelect = (item: any) => {
    const alreadyItem = itemsSelecteds.find(
      (data) => data.categoryId === item.categoryId
    );

    if (alreadyItem) {
      setItemsSelecteds(
        itemsSelecteds.filter((data) => data.categoryId !== item.categoryId)
      );
    } else if (item.generateKey === 'S') {
      setItemsSelecteds([...itemsSelecteds, item]);
    } else {
      setItemsSelecteds([item]);
    }
  };

  const onDragStart =
    (item: any, originArray: number) => (event: React.DragEvent) => {
      event.dataTransfer.setData('category', JSON.stringify(item));
      event.dataTransfer.setData('originArray', String(originArray));
    };

  const handleDrop =
    (areaId: number, index?: number) => (event: React.DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setDragAreaHover(null);
      setDragCategoryHover(null);

      const data = event.dataTransfer.getData('category');
      const category = JSON.parse(data);
      const origin = event.dataTransfer.getData('originArray');
      const originArray = Number(origin);

      const alreadyHasCategory = itemsSelecteds.find(
        (item) => item.categoryId === category.categoryId
      );

      const itemsToMove = alreadyHasCategory
        ? itemsSelecteds
        : [...itemsSelecteds, category];

      const newitems = items.filter((item) => {
        const hasItem = itemsToMove.find(
          (itemToMove) => itemToMove.categoryId === item.categoryId
        );

        return !hasItem || item.generateKey === 'N';
      });

      if (isAutoOrganize) {
        let newItemsToAutoOrganize = [...itemsAutoOrganize];

        if (originArray !== CATEGORY_BASE_ID) {
          newItemsToAutoOrganize = newItemsToAutoOrganize.map(
            (item: any, index: number) => {
              if (originArray !== index) {
                return item;
              }

              return item.filter((category: any) => {
                const hasItem = itemsToMove.find(
                  (itemToMove) => itemToMove.categoryId === category.categoryId
                );

                return !hasItem;
              });
            }
          );
        }

        if (areaId !== CATEGORY_BASE_ID) {
          if (typeof index === 'number') {
            newItemsToAutoOrganize[areaId].splice(
              index,
              0,
              ...itemsToMove.filter((item) => item.generateKey === 'S')
            );
          } else {
            newItemsToAutoOrganize[areaId].push(
              ...itemsToMove.filter((item) => item.generateKey === 'S')
            );
          }
        }

        if (areaId === CATEGORY_BASE_ID) {
          if (typeof index === 'number') {
            newitems.splice(
              index,
              0,
              ...itemsToMove.filter((item) => item.generateKey === 'S')
            );
          } else {
            newitems.push(
              ...itemsToMove.filter((item) => item.generateKey === 'S')
            );
          }
        }

        setItems(newitems);
        setItemsAutoOrganize(newItemsToAutoOrganize);
        setItemsSelecteds([]);
        return;
      }

      let newAreasObject = areasObject.map((item) => {
        return {
          ...item,
          categories: item.categories.filter((categoryOrigin: any) => {
            const dataItem = itemsToMove.find(
              (itemToMove) =>
                itemToMove.categoryId === categoryOrigin.categoryId
            );

            return (
              !dataItem ||
              (categoryOrigin.generateKey === 'N' &&
                originArray === CATEGORY_BASE_ID)
            );
          }),
        };
      });

      if (areaId === CATEGORY_BASE_ID) {
        if (typeof index === 'number') {
          newitems.splice(
            index,
            0,
            ...itemsToMove.filter((item) => item.generateKey === 'S')
          );
        } else {
          newitems.push(
            ...itemsToMove.filter((item) => item.generateKey === 'S')
          );
        }
      } else {
        newAreasObject = newAreasObject.map((item) => {
          if (item.id === areaId) {
            if (typeof index === 'number') {
              item.categories.splice(
                index,
                0,
                ...itemsToMove.filter(
                  (itemToMove) =>
                    !item.categories.find(
                      (itemCategory: any) =>
                        itemCategory.categoryId === itemToMove.categoryId
                    )
                )
              );
            } else {
              item.categories.push(
                ...itemsToMove.filter(
                  (itemToMove) =>
                    !item.categories.find(
                      (itemCategory: any) =>
                        itemCategory.categoryId === itemToMove.categoryId
                    )
                )
              );
            }
          }

          return item;
        });
      }

      setItemsSelecteds([]);

      setTimeout(() => {
        setAreasObject(newAreasObject);
        setItems(newitems);
      }, 50);

      const newChanges = [...changes];

      if (areaId !== CATEGORY_BASE_ID) {
        newChanges.push(areaId);
      }

      if (originArray !== CATEGORY_BASE_ID) {
        newChanges.push(originArray);
      }

      setChanges([...new Set(newChanges)]);
    };

  const allowDrop =
    (index?: number, categoryId?: number) => (event: React.DragEvent) => {
      if (index || typeof index === 'number') {
        setDragAreaHover(index);
      }

      if (categoryId || typeof categoryId === 'number') {
        setDragCategoryHover(categoryId);
      }
      event.preventDefault();
    };

  const applyFilters = useCallback(
    (items: any, filterByGenerateKey = false) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.generateKey ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.genderMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (filters.category && item.categoryName !== filters.category) {
            avaliable = false;
          }
          if (filters.gender && item.genderName !== filters.gender) {
            avaliable = false;
          }
          if (filters.weight && item.weightName !== filters.weight) {
            avaliable = false;
          }
          if (filters.track && item.trackName !== filters.track) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item.typeCategoryName !== filters.typeCategory
          ) {
            avaliable = false;
          }

          if (
            filterByGenerateKey &&
            filters.generateKey &&
            item.generateKey !== filters.generateKey
          ) {
            avaliable = false;
          }

          if (
            filters.typeCategoryMultiple?.length &&
            !filters.typeCategoryMultiple.includes(item.typeCategoryName)
          ) {
            avaliable = false;
          }

          if (
            filters.categoryMultiple?.length &&
            !filters.categoryMultiple.includes(item.categoryName)
          ) {
            avaliable = false;
          }

          if (
            filters.genderMultiple?.length &&
            !filters.genderMultiple.includes(item.genderName)
          ) {
            avaliable = false;
          }

          if (
            filters.weightMultiple?.length &&
            !filters.weightMultiple.includes(item.weightName)
          ) {
            avaliable = false;
          }

          if (
            filters.trackMultiple?.length &&
            !filters.trackMultiple.includes(item.trackName)
          ) {
            avaliable = false;
          }

          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const formatName = (item: any): string => {
    return item.descriptionCategory;
  };

  console.log('changes', changes);

  const countNumberFight = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return (
          Number(prev) +
          (current.generateKey === 'S' ? Number(current.numberFights) : 0)
        );
      },
      [0]
    );
  };

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array.length) return [];
    return array.sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();

      if (timeA !== timeB) {
        return timeA - timeB;
      }

      return a.order - b.order;
    });
  };

  const getTimeTotal = (categories: any[]): string => {
    if (!categories.length) {
      return '--';
    }
    const firstCategory = categories.find((item) => item.order === 0);
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!firstCategory || !lastCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);
    const lastFightsSorted = sortArrayV2(lastCategory.fights);

    const firstFight = fisrtFightsSorted[0];
    const lastFight = lastFightsSorted[lastFightsSorted.length - 1];

    if (!firstFight || !lastFight) {
      return '';
    }

    const diff = differenceInMinutesCeil(lastFight.date, firstFight.date);

    return `${formatMinutesToTime(diff)}`;
  };

  const getTimeTotalArea = (item: any): string => {
    if (changes?.includes(item?.id)) {
      return '--';
    }

    return getTimeTotal(item.categories);
  };

  const getFinalEta = (categories: any[]): string => {
    if (!categories.length) {
      return 'xx:xx';
    }
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!lastCategory) {
      return 'xx:xx';
    }

    const fightsSorted = sortArrayV2(lastCategory.fights);

    if (!fightsSorted.length) {
      return 'xx:xx';
    }

    return formatOnlyHour(fightsSorted[fightsSorted.length - 1].date);
  };

  const getFinalEtaArea = (item: any): string => {
    if (changes?.includes(item?.id)) {
      return 'xx:xx';
    }

    return getFinalEta(item.categories);
  };

  const getFistDate = (categories: any[]): string => {
    if (!categories.length) {
      return '--';
    }
    const firstCategory = categories.find((item) => item.order === 0);

    if (!firstCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);

    const firstFight = fisrtFightsSorted[0];

    if (!firstFight) {
      return '';
    }

    return formatOnlyHour(firstFight.date);
  };

  const getInitialTime = (item: any): string => {
    if (changes?.includes(item?.id)) {
      return '--';
    }

    return getFistDate(item.categories);
  };

  const countNumberAthletes = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return (
          Number(prev) +
          (current.generateKey === 'S' ? Number(current.numberAthletes) : 0)
        );
      },
      [0]
    );
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  const renderCategories = useMemo(() => {
    return applyFilters(items, true);
  }, [applyFilters, items]);

  const allSelected = useMemo(() => {
    if (!renderCategories.length) {
      return false;
    }

    return renderCategories.every((category: any) => {
      const isSelected = itemsSelecteds.find(
        (item) => item.categoryId === category.categoryId
      );

      return Boolean(isSelected);
    });
  }, [renderCategories, itemsSelecteds]);

  const someSelected = useMemo(() => {
    return renderCategories.some((category: any) => {
      const isSelected = itemsSelecteds.find(
        (item) => item.categoryId === category.categoryId
      );

      return Boolean(isSelected);
    });
  }, [renderCategories, itemsSelecteds]);

  const handleSelectAll = useCallback(() => {
    if (someSelected) {
      setItemsSelecteds([]);
    } else {
      setItemsSelecteds([...renderCategories]);
    }
  }, [renderCategories, someSelected]);

  const renderCheckbox = useMemo(() => {
    if (allSelected) {
      return (
        <Tooltip title="Selecionar" arrow>
          <IconButton
            onClick={handleSelectAll}
            style={{ background: '#dedede' }}
          >
            <CheckBoxOutlinedIcon />
          </IconButton>
        </Tooltip>
      );
    }

    if (someSelected) {
      return (
        <Tooltip title="Selecionar" arrow>
          <IconButton
            onClick={handleSelectAll}
            style={{ background: '#dedede' }}
          >
            <IndeterminateCheckBoxOutlinedIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Selecionar" arrow>
        <IconButton onClick={handleSelectAll}>
          <CheckBoxOutlineBlankOutlinedIcon />
        </IconButton>
      </Tooltip>
    );
  }, [allSelected, handleSelectAll, someSelected]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const orderAreasObject = (array: any[]): any[] => {
    return array.sort((a, b) => {
      if (a.day !== b.day) {
        return a.day - b.day;
      }
      return a.name.localeCompare(b.name);
    });
  };

  const autoOrganizeAreas = (items: any[], areas: any[]): any[] => {
    areas.forEach((area: any) => {
      // eslint-disable-next-line no-param-reassign
      area.categories = [];
    });

    const newAreas: any[] = [];

    items.forEach((item, index) => {
      const areasByDay = areas.filter((item: any) => item.day === index);

      item.forEach((category: any, index: number) => {
        const areaIndex = index % areasByDay.length;
        areasByDay[areaIndex].categories.push(category);
      });

      newAreas.push(...areasByDay);
    });

    return newAreas;
  };

  const handleResolveAutoOrganize = () => {
    setIsLoadingAutoOrganize(true);

    const areas = orderAreasObject(areasObject);

    const areasOrganized = autoOrganizeAreas(itemsAutoOrganize, areas);

    setTimeout(() => {
      setAreasObject(areasOrganized);

      setAutoOrganize(false);

      const changeIds = areasObject.map((item) => item.id);

      setChanges(changeIds);

      setIsLoadingAutoOrganize(false);
    }, 2000);
  };

  const handleAutoOrganize = () => {
    if (!itemsAutoOrganize?.length) {
      setModalConfirm({
        open: true,
        title: 'Nenhuma categoria selecionada',
        message:
          'Selecione e adicione as categorias que deseja organizar automaticamente.',
        confirmLabel: 'Adicionar agora',
        onConfirm: () => {
          setModalConfirm((prev: any) => ({
            ...prev,
            open: false,
          }));
        },
      });

      return;
    }

    setModalConfirm({
      open: true,
      title: 'Distribuir categorias automaticamente',
      confirmLabel: 'Confirmar',
      message:
        'As categorias selecionadas serão distribuidas nas áreas. Deseja continuar?',
      onConfirm: () => {
        handleResolveAutoOrganize();
        setModalConfirm((prev: any) => ({
          ...prev,
          open: false,
        }));
      },
      onCancel: () => {
        setModalConfirm((prev: any) => ({
          ...prev,
          open: false,
        }));
      },
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          padding: '0px 24px 24px 24px',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ textAlign: 'center', fontSize: 18 }}>Organizar áreas</div>

        {isAutoOrganize && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '16px 0px',
              gap: 16,
            }}
          >
            <Button
              color="inherit"
              variant="contained"
              disabled={isLoading}
              size="small"
              onClick={() => {
                prepareData();
                setAutoOrganize(false);
                initItemsAutoOrganize();
              }}
              style={{
                letterSpacing: 1,
                fontSize: 14,
              }}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isLoading}
              size="small"
              onClick={() => handleAutoOrganize()}
              style={{
                letterSpacing: 1,
                fontSize: 14,
              }}
            >
              Distribiur categorias
            </Button>
          </div>
        )}

        {!isAutoOrganize && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 0px',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={isLoading}
              size="small"
              onClick={() => {
                setAutoOrganize(true);
                setItems([...categories]);
                initItemsAutoOrganize();
              }}
              style={{
                letterSpacing: 1,
                fontSize: 14,
              }}
            >
              Organizar automaticamente
            </Button>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: 16,
                gap: 12,
                boxSizing: 'border-box',
                alignItems: 'center',
              }}
            >
              {Boolean(updatedAt) && (
                <div style={{ color: '#777' }}>
                  Salvo às {formatOnlyHour(updatedAt, true)}
                </div>
              )}
              {Boolean(isUpdating) && (
                <div style={{ color: '#777' }}>Atualizando...</div>
              )}

              <Button
                color="primary"
                variant="contained"
                disabled={isLoading}
                size="small"
                onClick={() => handleSubmit(areasObject)}
                style={{
                  letterSpacing: 1,
                  fontSize: 14,
                }}
              >
                Gerar lutas
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isLoading}
                size="small"
                onClick={() => handleFinish()}
                style={{
                  letterSpacing: 1,
                  fontSize: 14,
                }}
              >
                Visualizar áreas
              </Button>
            </div>
          </div>
        )}

        {!!filtersData && (
          <div>
            <div>Filtros</div>
            <div>
              <FilterOrganizeAreaV2
                isAutoOrganize={isAutoOrganize}
                categories={categories}
                filtersData={filtersData}
                handleSubmit={(values) =>
                  setFilters({ ...values, generateKey: filters.generateKey })
                }
                hasFiltersApplied={Boolean(
                  filters.category ||
                    filters.gender ||
                    filters.track ||
                    filters.weight ||
                    filters.typeCategory ||
                    filters.typeCategoryMultiple?.length ||
                    filters.categoryMultiple?.length ||
                    filters.weightMultiple?.length ||
                    filters.trackMultiple?.length ||
                    filters.genderMultiple?.length
                )}
              />
            </div>
          </div>
        )}

        <div
          style={{ paddingTop: 24, display: 'flex' }}
          hidden={isAutoOrganize}
        >
          <Select
            value={daySelected}
            onChange={(event) => setDaySelected(event.target.value)}
            style={{ width: 340, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
          >
            {days.map((item) => (
              <MenuItem key={item} value={item}>
                Dia {item + 1}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            gap: 12,
          }}
        >
          <div
            style={{
              width: 340,
              paddingTop: 24,
            }}
          >
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <div>{renderCheckbox}</div>
              <div style={{ flex: 1 }}>
                Categorias ({renderCategories.length})
              </div>
              <div>
                <div
                  style={{
                    color: '#2196f3',
                    cursor: 'pointer',
                    paddingRight: 16,
                    boxSizing: 'border-box',
                  }}
                  hidden={isAutoOrganize}
                  onClick={handleClick}
                >
                  {filters.generateKey === 'S' ? 'Lutas' : 'Apresentação'}
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setFilters({ ...filters, generateKey: 'S' });
                    }}
                  >
                    Lutas
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setFilters({ ...filters, generateKey: 'N' });
                    }}
                  >
                    Apresentação
                  </MenuItem>
                </Menu>
              </div>
            </div>

            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: 8,
                height: 400,
                marginTop: 12,
                overflowY: 'auto',
                width: 336,
                background:
                  dragAreaHover === CATEGORY_BASE_ID ? '#eeeeeeee' : undefined,
              }}
              onDragLeave={() => {
                setDragAreaHover(null);
              }}
              onDrop={handleDrop(CATEGORY_BASE_ID)}
              onDragOver={allowDrop(CATEGORY_BASE_ID)}
            >
              <div
                style={{
                  display: 'flex',
                  fontSize: 12,
                  padding: 10,
                  boxSizing: 'border-box',
                }}
              >
                <div
                  style={{
                    width: 260,
                    boxSizing: 'border-box',
                  }}
                >
                  Categoria
                </div>
                <div
                  style={{
                    width: 40,
                    textAlign: 'center',
                    boxSizing: 'border-box',
                  }}
                >
                  Lutas
                </div>
              </div>
              {renderCategories.map((item: any, index: number) => (
                <StyledItemCategory
                  key={index}
                  draggable
                  onDragStart={onDragStart(item, CATEGORY_BASE_ID)}
                  onClick={() => {
                    handleSelect(item);
                  }}
                  onDrop={handleDrop(CATEGORY_BASE_ID, index)}
                  onDragOver={allowDrop(CATEGORY_BASE_ID, index)}
                  onDragLeave={() => {
                    setDragCategoryHover(null);
                  }}
                  drophover={index === dragCategoryHover}
                  selected={
                    !!itemsSelecteds.find(
                      (data) => data.categoryId === item.categoryId
                    )
                  }
                >
                  <div style={{ width: 260 }}>{formatName(item)}</div>
                  <div style={{ width: 60, textAlign: 'center' }}>
                    {item.generateKey === 'S'
                      ? item.numberFights
                      : `${Number(item.categoryTime)} min`}
                  </div>
                </StyledItemCategory>
              ))}
            </div>
          </div>

          <div
            style={{
              width: 'calc(100% - 340px)',
              overflowX: 'auto',
              display: 'flex',
              gap: 12,
            }}
            hidden={!isAutoOrganize}
          >
            {days?.map((day) => (
              <div
                key={day}
                style={{
                  width: 340,
                  paddingTop: 24,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    height: 40,
                  }}
                >
                  <div>Categorias para distribuir - Dia {day + 1}</div>
                </div>

                <div
                  style={{ width: 'calc(100% - 340px)', background: 'blue' }}
                >
                  <div
                    onDrop={handleDrop(day)}
                    onDragLeave={() => {
                      setDragAreaHover(null);
                    }}
                    onDragOver={allowDrop(day)}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 8,
                      width: 340,
                      height: 400,
                      marginTop: 12,
                      overflowY: 'auto',
                      background:
                        day === dragAreaHover ? '#eeeeeeee' : undefined,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        fontSize: 12,
                        padding: 10,
                        boxSizing: 'border-box',
                      }}
                    >
                      <div
                        style={{
                          width: 260,
                          boxSizing: 'border-box',
                        }}
                      >
                        Categoria
                      </div>
                      <div
                        style={{
                          width: 40,
                          textAlign: 'center',
                          boxSizing: 'border-box',
                        }}
                      >
                        Lutas
                      </div>
                    </div>
                    {itemsAutoOrganize?.[day]?.map(
                      (category: any, indexCategory: number) => (
                        <StyledItemCategory
                          key={`${day}-${indexCategory}`}
                          draggable
                          onDragStart={onDragStart(category, day)}
                          onClick={() => handleSelect(category)}
                          onDrop={handleDrop(day, indexCategory)}
                          onDragOver={allowDrop(day, category.categoryId)}
                          onDragLeave={() => {
                            setDragCategoryHover(null);
                          }}
                          drophover={category.categoryId === dragCategoryHover}
                          selected={
                            !!itemsSelecteds.find(
                              (data) => data.categoryId === category.categoryId
                            )
                          }
                        >
                          <div style={{ width: 260 }}>
                            {formatName(category)}
                          </div>
                          <div
                            style={{
                              width: 60,
                              textAlign: 'center',
                            }}
                          >
                            {category.generateKey === 'S'
                              ? category.numberFights
                              : `${Number(category.categoryTime)} min`}
                          </div>
                        </StyledItemCategory>
                      )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div style={{ width: 'calc(100% - 340px)' }} hidden={isAutoOrganize}>
            {renderObjectDay.map((day, index) => (
              <React.Fragment key={`${index}-${day}`}>
                <div
                  style={{
                    width: '100%',
                    overflowX: 'auto',
                    display: 'flex',
                    gap: 12,
                    paddingTop: 24,
                  }}
                >
                  {filterByDay(areasObject, day).map((item, index) => (
                    <div key={index} style={{ minWidth: 340, width: 340 }}>
                      <div
                        style={{
                          display: 'flex',
                          gap: 8,
                          alignItems: 'center',
                          height: 40,
                        }}
                      >
                        <div>{item.name}</div>
                      </div>

                      <div
                        onDrop={handleDrop(item.id)}
                        onDragLeave={() => {
                          setDragAreaHover(null);
                        }}
                        onDragOver={allowDrop(item.id)}
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: 8,
                          width: 340,
                          height: 400,
                          marginTop: 12,
                          overflowY: 'auto',
                          background:
                            item.id === dragAreaHover ? '#eeeeeeee' : undefined,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            fontSize: 12,
                            padding: 10,
                            boxSizing: 'border-box',
                          }}
                        >
                          <div
                            style={{
                              width: 260,
                              boxSizing: 'border-box',
                            }}
                          >
                            Categoria
                          </div>
                          <div
                            style={{
                              width: 40,
                              textAlign: 'center',
                              boxSizing: 'border-box',
                            }}
                          >
                            Lutas
                          </div>
                        </div>
                        {item.categories?.map(
                          (category: any, indexCategory: number) => (
                            <StyledItemCategory
                              key={`${index}-${indexCategory}`}
                              draggable
                              onDragStart={onDragStart(category, item.id)}
                              onClick={() => handleSelect(category)}
                              onDrop={handleDrop(item.id, indexCategory)}
                              onDragOver={allowDrop(
                                item.id,
                                category.categoryId
                              )}
                              onDragLeave={() => {
                                setDragCategoryHover(null);
                              }}
                              drophover={
                                category.categoryId === dragCategoryHover
                              }
                              selected={
                                !!itemsSelecteds.find(
                                  (data) =>
                                    data.categoryId === category.categoryId
                                )
                              }
                            >
                              <div style={{ width: 260 }}>
                                {formatName(category)}
                              </div>
                              <div
                                style={{
                                  width: 60,
                                  textAlign: 'center',
                                }}
                              >
                                {category.generateKey === 'S'
                                  ? category.numberFights
                                  : `${Number(category.categoryTime)} min`}
                              </div>
                            </StyledItemCategory>
                          )
                        )}
                      </div>
                      <div style={{ paddingTop: 12, boxSizing: 'border-box' }}>
                        <div>Nº de categorias: {item.categories.length}</div>
                        <div>
                          Nº de atletas: {countNumberAthletes(item.categories)}
                        </div>
                        <div>
                          Nº de lutas: {countNumberFight(item.categories)}
                        </div>
                        <div>Horário inicial: {getInitialTime(item)}</div>
                        <div>Tempo total: {getTimeTotalArea(item)}</div>
                        <div>Estimativa final: {getFinalEtaArea(item)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <LoadingModal
        open={isLoadingAutoOrganize}
        message="Aguarde enquanto estamos distribuindo as categorias"
        title="Distribiur automaticamente"
      />
    </React.Fragment>
  );
};

export default OrganizeAreas;

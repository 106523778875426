import styled from 'styled-components';

export const StyledItem = styled('div')({
  display: 'flex',
  gap: 12,
  width: '100%',
  alignItems: 'center',
  padding: 12,
  boxSizing: 'border-box',
  borderRadius: 4,
  transition: '0.4s',
  '&:hover': {
    background: '#e1e1e1',
  },
});

import { DialogContent } from '@mui/material';
import styled from 'styled-components';

export const StyledDialogContent = styled(DialogContent)(() => ({
  minWidth: 150,
  minHeight: 150,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
  flexDirection: 'column',
}));

import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import MonitorIcon from '@mui/icons-material/Monitor';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RefreshIcon from '@mui/icons-material/Refresh';

import Replay5Icon from '@mui/icons-material/Replay5';
import Forward5Icon from '@mui/icons-material/Forward5';

import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { StyledContentTime, StyledFightOne, StyledRoot } from './styles';

import {
  startedFightRequest,
  stoppedFightRequest,
  finishPresentationRequest,
} from '../../../../api/scoreboard';

import useAuthenticate from '../../../../recoil/hooks/authenticate';
import ConfirmModal from '../../../../components/ConfirmModal';

interface Props {
  scoreDetails: any;
}

const Presentation: React.FC<Props> = ({ scoreDetails }): ReactElement => {
  const navigate = useNavigate();

  const { authenticate } = useAuthenticate();

  const { eventId, areaId, boardId } = useParams();

  const [time, setTime] = useState(
    scoreDetails?.eventAreaCategory?.timeCategory * 60
  );
  const [defaultTime] = useState(
    scoreDetails?.eventAreaCategory?.timeCategory * 60
  );
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [startedFight, setStartedFight] = useState(false);

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    confirmLabel?: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Atenção',
    message: 'Deseja inverter a ordem dos atletas?',
    onConfirm: () => null,
  });

  const handlePlayPause = () => {
    setStartedFight(true);
    setIsRunning((prevIsRunning) => !prevIsRunning);
  };

  const handleFinishPresentation = async () => {
    try {
      if (authenticate.token && boardId) {
        await finishPresentationRequest(authenticate.token, boardId);

        const otherFights = localStorage.getItem('allFights');

        if (otherFights) {
          const fights = JSON.parse(otherFights);

          const currentIndex = fights.indexOf(boardId);

          if (currentIndex !== -1 && currentIndex < fights.length - 1) {
            const nextId = fights[currentIndex + 1];

            setModalConfirm({
              open: true,
              message: 'Ir para a próxima luta',
              confirmLabel: 'Próxima luta',
              onConfirm: () => {
                setModalConfirm({ ...modalConfirm, open: false });
                navigate(
                  `/events/${eventId}/scoreboard/${areaId}/fight/${nextId}`
                );
                // eslint-disable-next-line dot-notation
                window.location.reload();
              },
              onCancel: () => {
                navigate(`/dash/events/${eventId}/scoreboard/${areaId}`);
              },
              title: 'Próxima luta',
            });
            return;
          }
        }

        navigate(`/dash/events/${eventId}/scoreboard/${areaId}`);
      }
    } catch (erro) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleStopFight = async () => {
    setStartedFight(false);

    setModalConfirm({
      ...modalConfirm,
      title: 'Concluir apresentação',
      message: 'Deseja concluir a apresentação?',
      open: true,
      onConfirm: () => {
        setModalConfirm({ ...modalConfirm, open: false });
        handleFinishPresentation();
      },
    });

    if (time) {
      setIsRunning(false);
    }

    try {
      if (authenticate.token && boardId) {
        await stoppedFightRequest(authenticate.token, boardId);
      }
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning && time > 0) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem(`@ilutas:${boardId}-time`, `${newTime}`);

          if (newTime === 0) {
            handleStopFight();

            clearInterval(intervalId);
          }
          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId, isRunning, time]);

  useEffect(() => {
    localStorage.setItem(`@ilutas:${boardId}-time`, `${time}`);
    localStorage.setItem(`@ilutas:board`, `${boardId}`);
    localStorage.setItem(
      `@ilutas:${boardId}-scoreDetails`,
      JSON.stringify(scoreDetails)
    );
  }, [boardId, time, scoreDetails]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(`@ilutas:${boardId}-time`);
      localStorage.removeItem(`@ilutas:board`);
      localStorage.removeItem(`@ilutas:${boardId}-scoreDetails`);
      localStorage.removeItem(`@ilutas:${boardId}-score`);
      localStorage.removeItem(`@ilutas:${boardId}-winner`);
      localStorage.removeItem(`@ilutas:${boardId}-withoutWinner`);
    };
  }, [boardId]);

  const handleResetTime = (): any => {
    localStorage.setItem(`@ilutas:${boardId}-time`, `${defaultTime}`);
    setTime(defaultTime);
    setIsRunning(false);
  };

  const handleStartFight = async () => {
    try {
      if (authenticate.token && boardId) {
        await startedFightRequest(authenticate.token, boardId);
        handlePlayPause();
      }
    } catch (erro) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleResetTimeConfirm = () => {
    setModalConfirm({
      ...modalConfirm,
      title: 'Atenção',
      message: 'Deseja resetar o tempo?',
      open: true,
      onConfirm: () => {
        handleResetTime();

        setModalConfirm({ ...modalConfirm, open: false });
      },
    });
  };

  const handleTime = (timeToAdd: number): void => {
    setTime((prevTime) => {
      const newTime = prevTime + timeToAdd;

      if (newTime > defaultTime) {
        localStorage.setItem(`@ilutas:${boardId}-time`, `${defaultTime}`);
        return defaultTime;
      }

      if (newTime < 0) {
        localStorage.setItem(`@ilutas:${boardId}-time`, `0`);
        return 0;
      }

      localStorage.setItem(`@ilutas:${boardId}-time`, `${newTime}`);
      return newTime;
    });
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  return (
    <React.Fragment>
      <StyledRoot>
        <StyledFightOne>
          <div>{scoreDetails?.eventAreaCategory?.categoryName || ''}</div>
        </StyledFightOne>

        <StyledContentTime>
          <div
            style={{
              flex: 1,
              padding: 12,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 120,
                fontWeight: 600,
                color: '#FFF',
                marginRight: 100,
              }}
            >
              {formatTime(time)}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'space-between',
                width: '100%',
                gap: 50,
                marginLeft: 30,
              }}
            >
              <div style={{ display: 'flex', gap: 12 }}>
                <Tooltip title="Espelhar placar em uma nova janela" arrow>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: 8,
                      padding: '4px 12px',
                      boxSizing: 'border-box',
                      gap: 16,
                      minWidth: 50,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/#/scoreboard/live`,
                        '_blank'
                      );
                    }}
                  >
                    <MonitorIcon fontSize="large" />
                  </div>
                </Tooltip>
              </div>

              {startedFight ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: 8,
                    padding: '4px 12px',
                    boxSizing: 'border-box',
                    gap: 16,
                    minWidth: 300,
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip title="Voltar 5 segundos" arrow>
                    <Replay5Icon
                      fontSize="large"
                      onClick={() => handleTime(5)}
                    />
                  </Tooltip>
                  {isRunning ? (
                    <Tooltip title="Pausar tempo" arrow>
                      <PauseIcon fontSize="large" onClick={handlePlayPause} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Retomar tempo" arrow>
                      <PlayArrowIcon
                        fontSize="large"
                        onClick={handlePlayPause}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Avançar 5 segundos" arrow>
                    <Forward5Icon
                      fontSize="large"
                      onClick={() => handleTime(-5)}
                    />
                  </Tooltip>
                  <Tooltip title="Resetar tempo" arrow>
                    <RefreshIcon
                      fontSize="large"
                      onClick={() => handleResetTimeConfirm()}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: 8,
                    padding: '4px 12px',
                    boxSizing: 'border-box',
                    gap: 16,
                    minWidth: 200,
                    cursor: 'pointer',
                  }}
                  onClick={handleStartFight}
                >
                  {scoreDetails?.status === 'in_progress'
                    ? 'Retomar apresentação'
                    : 'Iniciar apresentação'}
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: 8,
                  padding: '4px 12px',
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                }}
                onClick={handleStopFight}
              >
                Concluir apresentação
              </div>
            </div>
          </div>
          <div
            style={{
              width: '50%',
              flex: 1,
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 24,
            }}
          >
            <div style={{ fontSize: 48 }}>Apresentação</div>
          </div>
        </StyledContentTime>
      </StyledRoot>

      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmLabel={modalConfirm.confirmLabel}
        confirmColor="#07bc0c"
      />
    </React.Fragment>
  );
};

export default Presentation;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import {
  getEventAreaFights,
  getEventCategories,
  getReasonDesclassifiedRequest,
} from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';
import ModalInvalid from './ModalInvalid';
import NoAreas from '../../../components/NoAreas';
import ShowScoreboardAreaDetails from '../../../components/ShowScoreboardAreaDetails';

const ScoreboardAreaDetails: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { eventId, areaId } = useParams();

  const [event, setEvent] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [eventArea, setEventArea] = useState<any>(null);

  const [reasonDesclassified, setReasonDesclassified] = useState<any>([]);

  const [isModalInvalid, setModalInvalid] = useState(false);

  const [isLoading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
        setCategories(data?.categories || []);

        if (!data?.event?.numberAreas) {
          setModalInvalid(true);
        }
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getReasonDesclassified = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonDesclassifiedRequest(authenticate.token);

        setReasonDesclassified(data?.data || []);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  }, [authenticate.token]);

  useEffect(() => {
    getReasonDesclassified();
  }, [getReasonDesclassified]);

  const getEventAreaFightsData = useCallback(async () => {
    try {
      if (authenticate.token && eventId && areaId) {
        const data = await getEventAreaFights(
          authenticate.token,
          eventId,
          areaId
        );

        setEventArea(data?.area || null);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      //
    }
  }, [authenticate.token, eventId, areaId]);

  useEffect(() => {
    getEventAreaFightsData();
  }, [getEventAreaFightsData]);

  return (
    <StyledRoot>
      <ModalInvalid
        open={isModalInvalid}
        cause={[]}
        handleClose={() => navigate(`/dash/events/${eventId}/home?edit=true`)}
        message="Alguns campos do evento não estão configurados. Por favor, finaliza a configuração primeiro!"
      />

      <Card>
        <div style={{ width: '100%' }}>
          <DetailsInfo
            isLoading={isLoading}
            sections={[
              {
                rows: [
                  {
                    key: 'name',
                    label: 'Nome',
                  },
                  {
                    key: 'numberDaysFight',
                    label: 'Nº de dias',
                  },
                  {
                    key: 'numberAreas',
                    label: 'Nº de áreas',
                  },
                ],
                data: event,
                title: 'Evento',
              },
            ]}
          />

          {!isLoading && !!event && !eventArea && (
            <NoAreas
              title="Área não encontrada"
              message="Por favor, verifique os dados do evento!"
            />
          )}

          {!isLoading && !!event && !!eventArea && (
            <ShowScoreboardAreaDetails
              categories={categories}
              eventArea={eventArea}
              areas={event?.numberAreas}
              numberDays={event?.numberDaysFight}
              getData={getEventAreaFightsData}
              reasonDesclassified={reasonDesclassified}
            />
          )}
        </div>
      </Card>
    </StyledRoot>
  );
};

export default ScoreboardAreaDetails;

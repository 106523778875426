import React, { ReactElement } from 'react';

import { StyledRoot } from './styles';

const HeaderILutas: React.FC = (): ReactElement => {
  return (
    <StyledRoot>
      <div
        style={{
          maxWidth: '1590px',
          width: '100%',
        }}
      >
        <a href="https://ilutas.com.br">
          <img src={'/images/header-logo.png'} style={{ width: 180 }} />
        </a>
      </div>
    </StyledRoot>
  );
};

export default HeaderILutas;

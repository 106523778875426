import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '0px 24px 24px 24px',
  boxSizing: 'border-box',
  '@media (max-width: 900px) ': {
    padding: '0px 8px 24px 8px',
  },
}));

export const StyledItemCategory = styled(Box)(({ onClick }) => ({
  width: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  boxSizing: 'border-box',
  transition: '0.4s',
  cursor: onClick ? 'pointer' : '',
  '&:hover': {
    background: onClick ? '#ddd' : '#efefef',
  },
}));

export const StyledCustomTabPanel = styled('div')(() => ({
  padding: '24px 0px',
  '@media (max-width: 900px) ': {
    padding: '12px 0px',
  },
}));

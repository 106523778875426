import styled from 'styled-components';

export const StyledRoot = styled.span`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

interface Props {
  disabled: boolean;
  selected: boolean;
}

export const StyledItem = styled('div')<Props>(({ disabled, selected }) => ({
  border: selected ? '2px solid green' : '2px solid #ccc',
  background: selected ? '#dcedc8' : '',
  padding: 16,
  boxSizing: 'border-box',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  gap: 8,
  pointerEvents: disabled ? 'none' : undefined,
  cursor: 'pointer',
  transition: '0.4s',
  position: 'relative',
  '&:hover': !disabled
    ? {
        background: '#dcedc8',
      }
    : undefined,
}));

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Avatar,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { StyledItem } from './styles';

interface Props {
  open: boolean;
  athlete: any;
  title?: string;
  reasonDesclassified: { id: string; description: string }[];
  backButtonText?: string;
  defaultValue?: 'ready' | 'desclassified';
  defaultReason?: string;
  handleBack: () => void;
  handleSubmit: (values: any) => void;
}

const CheckinModal: React.FC<Props> = ({
  open,
  athlete,
  reasonDesclassified,
  title = 'Checkin',
  backButtonText = 'Cancelar',
  defaultValue = 'ready',
  defaultReason,
  handleSubmit,
  handleBack,
}): React.ReactElement => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [status, setStatus] = React.useState<string>(defaultValue);
  const [reason, setReason] = React.useState('');

  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      setStatus(defaultValue);
      setReason('');
      setLoading(false);
      setErrorMessage('');
    }
  }, [open, defaultValue]);

  React.useEffect(() => {
    setStatus(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    if (defaultReason) {
      setReason(defaultReason);
    }
  }, [defaultReason]);

  const onSubmit = async () => {
    try {
      if (!status) {
        setErrorMessage('Por favor, selecione o status!');
        return;
      }

      if (status === 'desclassified' && !reason) {
        setErrorMessage('Por favor, selecione o motivo!');
        return;
      }

      setLoading(true);

      await handleSubmit({
        status,
        reason,
        id: athlete?.uuid,
      });
    } catch {
      // console
    } finally {
      setLoading(false);
    }
  };

  const handleSelectStatus = (value: string) => {
    setErrorMessage('');
    setStatus(value);
  };

  const handleSelectReason = (value: string) => {
    setErrorMessage('');
    setReason(value);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: 400,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              padding: '12px',
              borderRadius: 8,
            }}
          >
            <div>Atleta</div>
            <div
              style={{
                display: 'flex',
                gap: 16,
              }}
            >
              <StyledItem disabled={false} selected={true}>
                <div>
                  <Avatar
                    alt={athlete?.nameAthlete}
                    src={athlete?.photo}
                    sx={{ width: 42, height: 42 }}
                    style={{
                      border: '1px solid #000',
                    }}
                  />
                </div>
                <div>{athlete?.nameAthlete}</div>

                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                  <CheckIcon htmlColor="green" fontSize="small" />
                </div>
              </StyledItem>
            </div>
          </div>

          <div
            style={{
              padding: '0px 16px',
              boxSizing: 'border-box',
              gap: 12,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>Status</div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                value={status}
                label=""
                onChange={(e) => handleSelectStatus(e.target.value)}
              >
                <MenuItem value="" disabled>
                  Selecione
                </MenuItem>
                <MenuItem value="ready">Classificado</MenuItem>
                <MenuItem value="desclassified">Desclassificado</MenuItem>
              </Select>
            </FormControl>
          </div>

          {status === 'desclassified' && (
            <div
              style={{
                padding: '0px 16px',
                boxSizing: 'border-box',
                gap: 12,
                display: 'flex',
                flexDirection: 'column',
                marginTop: 12,
              }}
            >
              <div>Motivo</div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Selecione</InputLabel>
                <Select
                  value={reason}
                  label="Selecione"
                  onChange={(e) => handleSelectReason(e.target.value)}
                >
                  {reasonDesclassified?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
        <div
          style={{
            textAlign: 'center',
            fontSize: 14,
            color: '#f00',
            paddingTop: 24,
          }}
        >
          {errorMessage}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '24px 40px', boxSizing: 'border-box' }}>
        <Button
          style={{ color: '#222' }}
          onClick={handleBack}
          variant="outlined"
        >
          {backButtonText}
        </Button>
        <Button
          onClick={onSubmit}
          autoFocus
          variant="contained"
          style={{ width: 125 }}
        >
          {isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            'Confirmar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckinModal;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';

import {
  getPublicBracket,
  getReasonDesclassifiedRequest,
} from '../../../api/events';

import DetailsInfo from '../../../components/DetailsInfo';

import Stages from './Stages';

const EventAreasBrackets: React.FC = (): ReactElement => {
  const { eventId, categoryId } = useParams();

  const navigate = useNavigate();

  const [event, setEvent] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);

  const [eventAreasCategory, setEventAreasCategory] = useState<any>([]);

  const [isLoading, setLoading] = useState(true);

  const getBracketData = useCallback(async () => {
    try {
      setLoading(true);
      if (eventId && categoryId) {
        const data = await getPublicBracket(eventId, categoryId);

        setEvent(data?.event);

        setCategory(data?.category);

        setEventAreasCategory(data?.fights || []);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [categoryId, eventId]);

  useEffect(() => {
    getBracketData();
  }, [getBracketData]);

  const [reasonDesclassified, setReasonDesclassified] = useState<any>([]);

  const getReasonDesclassified = useCallback(async () => {
    try {
      const data = await getReasonDesclassifiedRequest();

      setReasonDesclassified(data?.data || []);
    } catch (error) {
      // console.log('error');
    }
  }, []);

  useEffect(() => {
    getReasonDesclassified();
  }, [getReasonDesclassified]);

  return (
    <StyledRoot>
      {/* <div style={{ width: '100%' }}> */}
      <DetailsInfo
        isLoading={isLoading}
        sections={[
          {
            rows: [
              {
                key: 'name',
                label: 'Nome',
              },
              {
                key: 'categoryName',
                label: 'Categoria',
              },
            ],
            data: {
              name: event?.name,
              categoryName: category?.categoryName,
            },
            title: 'Evento',
            onBack: () => navigate(-1),
          },
        ]}
      />

      <Stages
        stages={eventAreasCategory}
        reasonDesclassified={reasonDesclassified}
      />
      {/* </div>  */}
    </StyledRoot>
  );
};

export default EventAreasBrackets;

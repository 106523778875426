import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { editEvent, EditEventRequest, getEvent } from '../../../api/events';
import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';
import EditEventForm from '../../../components/EditEventForm';
import { EventFormProps } from '../../../components/EditEventForm/schema';
import useEvent from '../../../recoil/hooks/event';

const Events: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const { eventId } = useParams();

  const { setEvent: setEventRecoil } = useEvent();

  const [event, setEvent] = useState<any>(null);

  const [isEditing, setEditing] = useState(false);

  const [isLoadingEdit, setLoadingEdit] = useState(false);

  const [isLoading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEvent(authenticate.token, eventId);

        setEvent(data?.event || null);
        setEventRecoil(data?.event || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const prepareDate = (date: Date | null): string => {
    if (!date) {
      return '';
    }
    return `${date}`.split('-').reverse().join('/');
  };

  const handleSubmit = async (values: EventFormProps) => {
    setLoadingEdit(true);

    try {
      if (eventId) {
        // const event = fromBase64WithObfuscation(eventId || '');

        const startDate = prepareDate(values.startDate);
        const endDate = prepareDate(values.endDate);

        const body: EditEventRequest = {
          ...values,
          startDate,
          endDate,
        };

        await editEvent(eventId, body, authenticate.token);
        setEditing(false);
        getData();
        toast('Informações salvas com sucesso!', { type: 'success' });
      }
    } catch (error) {
      const message =
        'Não foi possível executar essa ação. Por favor, tente novamente!';
      toast(message, { type: 'error' });
    } finally {
      setLoadingEdit(false);
    }
  };

  return (
    <StyledRoot>
      <Card>
        {isEditing ? (
          <EditEventForm
            event={event}
            onSubmit={handleSubmit}
            handleCancel={() => setEditing(false)}
            isLoading={isLoadingEdit}
          />
        ) : (
          <React.Fragment>
            <div style={{ position: 'absolute', top: 16, right: 16 }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => setEditing(true)}
                disabled={isLoading}
                style={{
                  letterSpacing: 1,
                  fontSize: 14,
                }}
              >
                Editar
              </Button>
            </div>
            <DetailsInfo
              isLoading={isLoading}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                    {
                      key: 'startDate',
                      label: 'Data de início',
                    },
                    {
                      key: 'endDate',
                      label: 'Data de fim',
                    },
                    {
                      key: 'numberDaysFight',
                      label: 'Nº de dias de luta',
                    },
                    {
                      key: 'numberAreas',
                      label: 'Nº de áreas',
                    },

                    {
                      key: 'startTimeFight',
                      label: 'Horário de inicio das lutas',
                    },

                    {
                      key: 'timeBetweenFight',
                      label: 'Tempo entre as lutas',
                    },
                    {
                      key: 'timeBetweenCategory',
                      label: 'Tempo entre categorias',
                    },
                    {
                      key: 'timeCategoryOneAthlete',
                      label: 'Tempo para categoria com um atleta na chave',
                    },

                    {
                      key: 'numberThird',
                      label: 'Nº de teceiros',
                    },
                    {
                      key: 'typeKey3',
                      label: 'Será usada chave de três',
                      transform: (value) => (value === 'S' ? 'Sim' : 'Não'),
                    },

                    {
                      key: 'hasThirdPlaceFight',
                      label: 'Disputa de terceiro lugar',
                      transform: (value) => (value === 'S' ? 'Sim' : 'Não'),
                    },
                    {
                      key: 'athletesByeFowardAuto',
                      label: 'Atletas bye avança automático',
                      transform: (value) => (value ? 'Sim' : 'Não'),
                    },
                    {
                      key: 'typeDrawFight',
                      label: 'Tipo de desempate da luta',
                      ignoreEmptyValue: true,
                      transform: (value) => {
                        if (value === 'G') {
                          return 'Golden Score';
                        }

                        return 'Decisão do arbitro';
                      },
                    },
                    {
                      key: 'verifyAthleteDataAward',
                      label: 'Verificar atleta na premiação',
                      transform: (value) => (value ? 'Sim' : 'Não'),
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
            />
          </React.Fragment>
        )}
      </Card>
    </StyledRoot>
  );
};

export default Events;

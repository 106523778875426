/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Button, MenuItem, Select, Tab, Tabs } from '@mui/material';
import AreasCategory from './AreasCategory';
import AreasFights from './AreasFights';
import FilterOrganizeAreaV2 from './FilterOrganizeAreaV2';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: '0px 24px' }}>{children}</Box>
      )}
    </div>
  );
}

interface ShowAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  handleStart: () => void;
  handleChangeAthletes?: (athlete: any, origin: any) => void;
  handleChangeFight?: (fight: any, origin: any) => void;
  reasonDesclassified?: any[];
}

const ShowAreas: React.FC<ShowAreasProps> = ({
  categories,
  eventAreas,
  filtersData,
  numberDays,
  handleStart,
  reasonDesclassified,
  handleChangeAthletes,
  handleChangeFight,
}): ReactElement => {
  const [daySelected, setDaySelected] = useState('0');

  const [days, setDays] = useState<number[]>([]);

  const [filters, setFilters] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
    typeCategoryMultiple: [] as string[],
    categoryMultiple: [] as string[],
    trackMultiple: [] as string[],
    weightMultiple: [] as string[],
    genderMultiple: [] as string[],
  });

  useEffect(() => {
    const newDays: number[] = [];

    if (eventAreas?.length) {
      eventAreas.forEach((item) => {
        newDays.push(item.day);
      });

      setDays([...new Set(newDays)].sort());
    }
  }, [eventAreas]);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18 }}>Visualizar áreas</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: 16,
          boxSizing: 'border-box',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleStart}
          style={{
            letterSpacing: 1,
            fontSize: 14,
          }}
        >
          Editar
        </Button>
      </div>

      {!!filtersData && (
        <div>
          <div>Filtros</div>
          <div>
            <FilterOrganizeAreaV2
              categories={categories}
              filtersData={filtersData}
              handleSubmit={setFilters}
              hasFiltersApplied={Boolean(
                filters.category ||
                  filters.gender ||
                  filters.track ||
                  filters.weight ||
                  filters.typeCategory ||
                  filters.typeCategoryMultiple?.length ||
                  filters.categoryMultiple?.length ||
                  filters.weightMultiple?.length ||
                  filters.trackMultiple?.length ||
                  filters.genderMultiple?.length
              )}
            />
          </div>
        </div>
      )}

      <Box
        sx={{
          width: '100%',

          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <Tabs value={tabValue} onChange={handleChangeTabValue}>
            <Tab
              style={{
                width: 200,
                background: tabValue === 0 ? '#083061' : '#ddd',
                color: tabValue === 0 ? '#fff' : undefined,
                marginRight: 0,
              }}
              label="Categorias"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                width: 200,
                background: tabValue === 1 ? '#083061' : '#ddd',
                color: tabValue === 1 ? '#fff' : undefined,
                marginLeft: 0,
              }}
              label="Lutas"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <div style={{ padding: '24px' }}>
          <Select
            value={daySelected}
            onChange={(event) => setDaySelected(event.target.value)}
            style={{ width: 320, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
          >
            {days.map((item, index) => (
              <MenuItem key={index} value={index}>
                Dia {index + 1}
              </MenuItem>
            ))}
          </Select>
        </div>

        <CustomTabPanel value={tabValue} index={0}>
          <AreasCategory
            filters={filters}
            numberDays={numberDays}
            eventAreas={eventAreas}
            daySelected={daySelected}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <AreasFights
            filters={filters}
            numberDays={numberDays}
            eventAreas={eventAreas}
            daySelected={daySelected}
            reasonDesclassified={reasonDesclassified}
            handleChangeAthletes={handleChangeAthletes}
            handleChangeFight={handleChangeFight}
          />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default ShowAreas;

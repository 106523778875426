import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  StyleContentCard,
  StyledCard,
  StyledCardDescription,
  StyledCardImage,
  StyledContainer,
  StyledOutlet,
  StyledRoot,
} from './styles';
import { getPublicEvents } from '../../../api/events';
import Card from '../../../components/Card';

const Events: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const getData = useCallback(async () => {
    try {
      const data = await getPublicEvents();

      setEvents(data?.events || []);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmit = (keyEvent: string) => {
    navigate(`/events/${keyEvent}`);
  };

  const handleError = (event: any) => {
    // eslint-disable-next-line no-param-reassign
    event.target.hidden = true;
  };

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledOutlet>
          <StyleContentCard>
            <div style={{ paddingBottom: '32px', fontSize: 32 }}>
              <Typography component="h1" fontSize={32}>
                Eventos
              </Typography>
            </div>
            {events.map((item: any, index) => (
              <Card key={index} onClick={() => handleSubmit(item.keyEvent)}>
                <StyledCard>
                  <StyledCardImage style={{}}>
                    {Boolean(item.image) && (
                      <img
                        src={item.image}
                        style={{ width: 120 }}
                        onError={handleError}
                      />
                    )}
                  </StyledCardImage>
                  <StyledCardDescription style={{}}>
                    <Typography component="h2" fontSize={20}>
                      {item.name}
                    </Typography>
                    <div>Dia: {item.startDate}</div>
                    <div>Hora: {item.startTimeFight}</div>
                    <div>Nº de áreas: {item.numberAreas}</div>
                  </StyledCardDescription>
                </StyledCard>
              </Card>
            ))}
          </StyleContentCard>
        </StyledOutlet>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Events;

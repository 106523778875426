import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import { getEvent, getStatistics } from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import useEvent from '../../../recoil/hooks/event';
import StatisticsReports from '../../../components/StatisticsReports';

const Statistics: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const { eventId } = useParams();

  const [event, setEvent] = useState<any>(null);

  const [data, setData] = useState<any>(null);

  const { setEvent: setEventRecoil } = useEvent();

  const [isLoading, setLoading] = useState(true);
  const [isLoadingData, setLoadingData] = useState(true);

  const getEventRequest = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEvent(authenticate.token, eventId);

        setEvent(data?.event || null);
        setEventRecoil(data?.event || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getEventRequest();
  }, [getEventRequest]);

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      if (authenticate.token && eventId) {
        const result = await getStatistics(authenticate.token, eventId);

        setData(result?.data || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoadingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <React.Fragment>
      <StyledRoot>
        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoading || isLoadingData}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                    {
                      key: 'numberDaysFight',
                      label: 'Nº de dias',
                    },
                    {
                      key: 'numberAreas',
                      label: 'Nº de áreas',
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
            />

            {!isLoading && !isLoadingData && <StatisticsReports data={data} />}
          </div>
        </Card>
      </StyledRoot>
    </React.Fragment>
  );
};

export default Statistics;

/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';
import { StyledItemArea } from '../styles';
import { formatTime } from '../../../utils/date';

interface ScoreboardAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filters: any;
}

const ScoreboardAreas: React.FC<ScoreboardAreasProps> = ({
  categories,
  startTime,
  eventAreas,
  areas,
  numberDays,
}): ReactElement => {
  const navigate = useNavigate();
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [daySelected, setDaySelected] = useState('0');

  const [days, setDays] = useState<number[]>([]);

  useEffect(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      let newItems = [...categories];
      const newDays: number[] = [];
      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);
        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          uuid: item.uuid,
          name: item.name,
          categories: item.categories?.length
            ? item.categories
                .map((category: any) => {
                  const categoryData = categories.find((itemCategory) => {
                    return itemCategory.categoryId === category.category;
                  });

                  newItems = newItems.filter(
                    (item) => item.categoryId !== category.category
                  );

                  return {
                    ...categoryData,
                    order: category.order,
                    fights: category.fights,
                  };
                })
                .sort((a: any, b: any) => {
                  return a.order - b.order;
                })
            : [],
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
    }
  }, [areas, eventAreas, categories, numberDays]);

  // const countNumberFight = (categories: any[]): number => {
  //   return categories.reduce(
  //     (prev, current) => {
  //       return (
  //         Number(prev) +
  //         (current.generateKey === 'S' ? Number(current.numberFights) : 0)
  //       );
  //     },
  //     [0]
  //   );
  // };

  const countNumberFightScoreboard = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        const programedFights = current?.fights?.filter((item: any) =>
          ['programed'].includes(item.status)
        );

        return (
          Number(prev) +
          (current.generateKey === 'S' ? programedFights?.length : 0)
        );
      },
      [0]
    );
  };

  // const countNumberAthletes = (categories: any[]): number => {
  //   return categories.reduce(
  //     (prev, current) => {
  //       return (
  //         Number(prev) +
  //         (current.generateKey === 'S' ? Number(current.numberAthletes) : 0)
  //       );
  //     },
  //     [0]
  //   );
  // };

  const countNumberCategoriesScoreboard = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        const hasProgramedFight = current?.fights?.some((item: any) =>
          ['programed'].includes(item.status)
        );
        return (
          Number(prev) +
          (current.generateKey === 'S' && hasProgramedFight ? 1 : 0)
        );
      },
      [0]
    );
  };

  const countNumberAthletesScoreboard = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        const programedFights = current?.fights?.filter((item: any) =>
          ['programed'].includes(item.status)
        );

        let count = 0;

        programedFights?.forEach((fight: any) => {
          fight?.athletes?.forEach((athlete: any) => {
            if (athlete?.status === 'ready') {
              count += 1;
            }
          });
        });

        return Number(prev) + Number(count);
      },
      [0]
    );
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  return (
    <React.Fragment>
      <div style={{ paddingTop: 24 }}>
        <Select
          value={daySelected}
          onChange={(event) => setDaySelected(event.target.value)}
          style={{ width: 320, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          {days.map((item, index) => (
            <MenuItem key={index} value={index}>
              Dia {index + 1}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          {renderObjectDay.map((day, index) => (
            <React.Fragment key={`${index}-${day}`}>
              <div
                style={{
                  width: '100%',
                  overflowX: 'auto',
                  display: 'flex',
                  gap: 12,
                  paddingLeft: 8,
                  paddingTop: 48,
                }}
              >
                {filterByDay(areasObject, day).map((item, index) => (
                  <div key={index} style={{ minWidth: 200, width: 200 }}>
                    <StyledItemArea
                      onClick={() => {
                        navigate(`${item.uuid}`);
                      }}
                    >
                      <div>{item.name}</div>
                    </StyledItemArea>
                    <div style={{ paddingTop: 12, boxSizing: 'border-box' }}>
                      <div>Horário inicial: {formatTime(startTime)}</div>
                      <div>
                        Nº de categorias:{' '}
                        {countNumberCategoriesScoreboard(item.categories)}
                      </div>
                      <div>
                        Nº de atletas:{' '}
                        {countNumberAthletesScoreboard(item.categories)}
                      </div>
                      <div>
                        Nº de lutas:{' '}
                        {countNumberFightScoreboard(item.categories)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScoreboardAreas;

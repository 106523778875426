import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import {
  deliveryAwardRequest,
  finishCategoryAwardRequest,
  getAbsoluteCategories,
  getEventAreasFights,
  getEventCategories,
  subscribeAthleteAbsoluteRequest,
  verifyAthleteRequest,
} from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import ModalInvalid from './ModalInvalid';
import NoAreas from '../../../components/NoAreas';
import ShowAwards from '../../../components/ShowAwards';
import ConfirmModal from '../../../components/ConfirmModal';
import ModalAbsolute from './ModalAbsolute';

const Awards: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const isDash = useMemo(() => {
    return window.location.hash.includes('dash');
  }, []);

  const { eventId } = useParams();

  const [event, setEvent] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [eventAreas, setEventAreas] = useState<any>([]);

  const [isModalInvalid, setModalInvalid] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAreas, setLoadingAreas] = useState(true);

  const [modalAbsolute, setModalAbsolute] = useState({
    open: false,
    athlete: {},
    categories: [],
  });

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Verificar identidade do atleta',
    message: 'O atleta está presente e será verificado. Confirmar?',
    onConfirm: () => {
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
    onCancel: () => {
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
        setCategories(data?.categories || []);

        if (!data?.event?.numberAreas) {
          setModalInvalid(true);
        }
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getEventAreasFightsData = useCallback(
    async (loading = true) => {
      try {
        setLoadingAreas(loading);
        if (authenticate.token && eventId) {
          const data = await getEventAreasFights(authenticate.token, eventId);

          setEventAreas(data?.areas || []);
        }
      } catch (error) {
        toast(
          'Não foi possível executar essa ação. Por favor, tente novamente!',
          { type: 'error' }
        );
      } finally {
        setLoadingAreas(false);
      }
    },
    [authenticate.token, eventId]
  );

  useEffect(() => {
    getEventAreasFightsData();

    let intervalId: NodeJS.Timeout;

    if (!isDash) {
      intervalId = setInterval(() => {
        getEventAreasFightsData(false);
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [getEventAreasFightsData, isDash]);

  const isLoadingGeneral = useMemo(
    () => isLoading || isLoadingAreas,
    [isLoading, isLoadingAreas]
  );

  const handleVerifyAthlete = async (athlete: any): Promise<void> => {
    try {
      if (authenticate.token && eventId && athlete) {
        await verifyAthleteRequest(authenticate.token, eventId, athlete.uuid);

        toast('Atleta verificado com sucesso!', { type: 'success' });
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      getEventAreasFightsData(false);
    }
  };

  const handleDeliveryAward = async (athlete: any): Promise<void> => {
    try {
      if (authenticate.token && eventId && athlete) {
        await deliveryAwardRequest(authenticate.token, eventId, athlete.uuid);

        toast('Premiação entregue com sucesso!', { type: 'success' });
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      getEventAreasFightsData(false);
    }
  };

  const handleSubscribeAthleteAbsolute = async (
    athlete: any,
    category?: string
  ): Promise<void> => {
    try {
      if (authenticate.token && eventId && athlete) {
        setModalAbsolute((prev) => ({
          ...prev,
          open: false,
        }));

        await subscribeAthleteAbsoluteRequest(
          authenticate.token,
          eventId,
          athlete.uuid,
          !athlete?.athleteAbsoluteSubscribed,
          category
        );

        toast(
          athlete?.athleteAbsoluteSubscribed
            ? 'Inscrição cancelada com sucesso!'
            : 'Atleta inscrito com sucesso!',
          { type: 'success' }
        );
      }
    } catch (error: any) {
      let message =
        'Não foi possível executar essa ação. Por favor, tente novamente!';

      if (error?.response?.status === 418) {
        message = 'Falha ao realizar essa ação. Por favor, tente novamente!';
      }

      toast(message, { type: 'error' });
    } finally {
      getEventAreasFightsData(false);
    }
  };

  const handleFinishCategoryAward = async (
    categoryUuid: string
  ): Promise<void> => {
    try {
      if (authenticate.token && eventId && categoryUuid) {
        await finishCategoryAwardRequest(
          authenticate.token,
          eventId,
          categoryUuid
        );

        toast('Categoria ocultada com sucesso!', { type: 'success' });
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      getEventAreasFightsData(false);
    }
  };

  const verifyAthlete = (athlete: any) => {
    const props = {
      title: 'Verificar identidade do atleta',
      message: 'O atleta está presente e será verificado. Confirmar?',
    };

    setModalConfirm({
      ...modalConfirm,
      ...props,
      open: true,
      onCancel: () => {
        getEventAreasFightsData(false);
        setModalConfirm((prev) => ({
          ...prev,
          ...props,
          open: false,
        }));
      },
      onConfirm: () => {
        handleVerifyAthlete(athlete);
        setModalConfirm((prev) => ({
          ...prev,
          ...props,
          open: false,
        }));
      },
    });
  };

  const subscribeAthleteAbsolute = async (athlete: any) => {
    if (athlete?.athleteAbsoluteSubscribed) {
      const props = {
        title: 'Inscrever no absoluto',
        message: 'Cancelar inscrição do atleta no absoluto. Confirmar?',
      };

      setModalConfirm({
        ...modalConfirm,
        ...props,
        open: true,
        onCancel: () => {
          getEventAreasFightsData(false);
          setModalConfirm((prev) => ({
            ...prev,
            ...props,
            open: false,
          }));
        },
        onConfirm: () => {
          handleSubscribeAthleteAbsolute(athlete);
          setModalConfirm((prev) => ({
            ...prev,
            ...props,
            open: false,
          }));
        },
      });
      return;
    }

    try {
      if (authenticate.token && eventId) {
        const result = await getAbsoluteCategories(
          authenticate.token,
          eventId,
          athlete.uuid
        );

        setModalAbsolute({
          open: true,
          athlete,
          categories: result.absolutes || [],
        });
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const deliveryAward = (athlete: any) => {
    const props = {
      title: 'Entregar premiação',
      message: 'Marcar premiação como entregue. Confirmar?',
    };

    setModalConfirm({
      ...modalConfirm,
      ...props,
      open: true,
      onCancel: () => {
        getEventAreasFightsData(false);
        setModalConfirm((prev) => ({
          ...prev,
          ...props,
          open: false,
        }));
      },
      onConfirm: () => {
        handleDeliveryAward(athlete);
        setModalConfirm((prev) => ({
          ...prev,
          ...props,
          open: false,
        }));
      },
    });
  };

  const finishCategoryAward = (categoryUuid: string) => {
    const props = {
      title: 'Ocultar categoria',
      message:
        'A categoria será ocultada e a premiação marcada como entregue. Confirmar?',
    };

    setModalConfirm({
      ...modalConfirm,
      ...props,
      open: true,
      onCancel: () => {
        setModalConfirm((prev) => ({
          ...prev,
          ...props,
          open: false,
        }));
      },
      onConfirm: () => {
        handleFinishCategoryAward(categoryUuid);
        setModalConfirm((prev) => ({
          ...prev,
          ...props,
          open: false,
        }));
      },
    });
  };

  return (
    <React.Fragment>
      <StyledRoot>
        <ModalInvalid
          open={isModalInvalid}
          cause={[]}
          handleClose={() => navigate(`/dash/events/${eventId}/home?edit=true`)}
          message="Alguns campos do evento não estão configurados. Por favor, finaliza a configuração primeiro!"
        />

        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoadingGeneral}
              sections={[
                {
                  rows: [],
                  data: {},
                  title: '',
                },
              ]}
            />

            {!isLoadingGeneral && !!event && !eventAreas?.length && (
              <NoAreas
                title="Visualizar áreas"
                message="Nenhuma área configurada para o evento"
              />
            )}

            {!isLoadingGeneral && !!event && !!eventAreas?.length && (
              <ShowAwards
                categories={categories}
                eventAreas={eventAreas}
                event={event}
                areas={event?.numberAreas}
                numberDays={event?.numberDaysFight}
                verifyAthlete={verifyAthlete}
                deliveryAward={deliveryAward}
                finishCategoryAward={finishCategoryAward}
                subscribeAthleteAbsolute={subscribeAthleteAbsolute}
              />
            )}
          </div>
        </Card>
      </StyledRoot>
      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmColor="#333"
      />
      <ModalAbsolute
        open={modalAbsolute.open}
        categories={modalAbsolute.categories}
        handleClose={() => {
          setModalAbsolute((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
          getEventAreasFightsData(false);
        }}
        handleConfirm={(select) =>
          handleSubscribeAthleteAbsolute(modalAbsolute.athlete, select)
        }
      />
    </React.Fragment>
  );
};

export default Awards;

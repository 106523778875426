import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jsPDF as JsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import { getEventCategories } from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import EventAreasBrackets from '../../public/EventAreasBrackets';
import useLoading from '../../../recoil/hooks/loading';

const CategoryBrackets: React.FC = (): ReactElement => {
  const componentToExportRef = useRef<HTMLDivElement>(null);

  const { setLoadingModal } = useLoading();

  const { authenticate } = useAuthenticate();

  const { eventId } = useParams();

  const [event, setEvent] = useState<any>(null);

  const [isLoading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleExportPDF = async () => {
    setLoadingModal(true);
    const element = componentToExportRef.current;

    if (!element) return;

    const canvas = await html2canvas(element, { scale: 2, useCORS: true });
    const imgData = canvas.toDataURL('image/png');

    const pdf = new JsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });

    const pageWidth = pdf.internal.pageSize.getWidth();
    // const pageHeight = pdf.internal.pageSize.getHeight();

    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

    pdf.save('document.pdf');

    setLoadingModal(false);
  };

  return (
    <React.Fragment>
      <StyledRoot>
        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoading}
              actions={[
                {
                  icon: <PictureAsPdfIcon color="primary" />,
                  onClick: () => handleExportPDF(),
                  tooltip: 'Download PDF',
                },
              ]}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
            />

            <div ref={componentToExportRef}>
              <EventAreasBrackets hiddenHeader />
            </div>
          </div>
        </Card>
      </StyledRoot>
    </React.Fragment>
  );
};

export default CategoryBrackets;

import React, { ReactElement, useEffect, useState } from 'react';

import { StyledContentTime, StyledFightOne, StyledRoot } from './styles';

interface Props {
  scoreDetails: any;
}

const Presentation: React.FC<Props> = ({ scoreDetails }): ReactElement => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const updateTime = () => {
      const boardId = localStorage.getItem(`@ilutas:board`);
      const newTime = localStorage.getItem(`@ilutas:${boardId}-time`);

      setTime(Number(newTime));
    };

    updateTime();

    const interval = setInterval(() => {
      updateTime();
    }, 900);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  return (
    <React.Fragment>
      <StyledRoot>
        <StyledFightOne>
          <div>{scoreDetails?.eventAreaCategory?.categoryName || ''}</div>
        </StyledFightOne>

        <StyledContentTime>
          <div
            style={{
              flex: 1,
              padding: 12,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 120,
                fontWeight: 600,
                color: '#FFF',
                marginRight: 100,
              }}
            >
              {formatTime(time)}
            </div>
          </div>
          <div
            style={{
              width: '50%',
              flex: 1,
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 24,
            }}
          >
            <div style={{ fontSize: 48 }}>Apresentação</div>
          </div>
        </StyledContentTime>
      </StyledRoot>
    </React.Fragment>
  );
};

export default Presentation;

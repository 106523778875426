import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useAuthenticate from '../../../recoil/hooks/authenticate';
import { getScoreboardDetails } from '../../../api/scoreboard';
import Presentation from './Presentation';
import Fight from './Fight';
import useEvent from '../../../recoil/hooks/event';
import { EventTypeDrawFight } from '../../../interfaces/event';
import useLoading from '../../../recoil/hooks/loading';

const Scoreboard: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const { setLoadingModal } = useLoading();

  const { authenticate } = useAuthenticate();

  const { event } = useEvent();

  const isGoldenScore = useMemo(() => {
    return event?.typeDrawFight === EventTypeDrawFight.GOLDEN_SCORE;
  }, [event]);

  const { eventId, areaId, boardId } = useParams();

  const [scoreDetails, setScoreDetails] = useState<any>(null);

  const getData = useCallback(async () => {
    try {
      setLoadingModal(true);
      if (authenticate.token && boardId) {
        const data = await getScoreboardDetails(authenticate.token, boardId);

        if (
          data?.details?.status === 'completed' ||
          data?.details?.status === 'bye'
        ) {
          navigate(`/dash/events/${eventId}/scoreboard/${areaId}`);
          return;
        }

        setScoreDetails(data?.details || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, boardId]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (scoreDetails?.type === 'presentation') {
    return <Presentation scoreDetails={scoreDetails} />;
  }

  return (
    <Fight
      scoreDetails={scoreDetails}
      getData={getData}
      isGoldenScore={isGoldenScore}
    />
  );
};

export default Scoreboard;

import { Box } from '@mui/material';
import styled from 'styled-components';

const getBackground = (selected: boolean, drophover?: boolean) => {
  if (drophover) {
    return '#bbbbbbbb';
  }

  if (selected) {
    return '#083061';
  }

  return undefined;
};

export const StyledItemCategory = styled(Box)<{
  selected: boolean;
  drophover?: boolean;
}>(({ selected, drophover }) => ({
  width: 320,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 10px',
  boxSizing: 'border-box',
  transition: '0.4s',
  background: getBackground(selected, drophover),
  color: selected ? '#fff' : undefined,
  cursor: 'pointer',

  '&:hover': {
    background: '#083061aa',
    color: '#fff',
  },

  '&:active': {
    background: '#083061',
    color: '#fff',
  },
}));

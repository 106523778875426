/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import FightItem from './FightItem';
import FightPresentation from './FightPresentation';
import { compareStrings } from '../../../utils/string';

interface AreasFightsProps {
  eventAreas?: any[];
  numberDays: number;
  filters: any;
  daySelected: string;
  reasonDesclassified?: any[];
  search?: string;
  redirect?: (item: any) => void;
  handleChangeAthletes?: (athlete: any, origin: any) => void;
  handleChangeFight?: (fight: any, origin: any) => void;
}

const AreasFights: React.FC<AreasFightsProps> = ({
  eventAreas,
  numberDays,
  reasonDesclassified,
  daySelected,
  filters,
  search,
  redirect,
  handleChangeAthletes,
  handleChangeFight,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [days, setDays] = useState<number[]>([]);

  const applySearch = useCallback(
    (items: any[]) => {
      if (!search) {
        return items;
      }

      return items.filter((item) => {
        let hasItem = false;

        for (const athlete of item.athletes) {
          if (compareStrings(athlete.nameAthlete, search)) {
            hasItem = true;
            break;
          }
        }

        return hasItem;
      });
    },
    [search]
  );

  useEffect(() => {
    if (eventAreas?.length) {
      const newDays: number[] = [];

      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);

        const fights: any[] = [];

        item.categories.forEach((category: any) => {
          category.fights.forEach((fight: any) => {
            fights.push({
              ...fight,
              category,
            });
          });
        });

        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          name: item.name,
          categories: [],
          fights,
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
    }
  }, [eventAreas, numberDays]);

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array.length) return [];
    return array.sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();

      if (timeA !== timeB) {
        return timeA - timeB;
      }

      return a.order - b.order;
    });
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.genderMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (
            filters.category &&
            !item?.category?.categoryName?.includes(filters.category)
          ) {
            avaliable = false;
          }
          if (
            filters.gender &&
            !item?.category?.categoryName?.includes(filters.gender)
          ) {
            avaliable = false;
          }
          if (
            filters.weight &&
            !item?.category?.categoryName?.includes(filters.weight)
          ) {
            avaliable = false;
          }
          if (
            filters.track &&
            !item?.category?.categoryName?.includes(filters.track)
          ) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item?.category?.typeCategory !== filters.typeCategory
          ) {
            avaliable = false;
          }

          if (
            filters.typeCategoryMultiple?.length &&
            !filters.typeCategoryMultiple.includes(item?.category?.typeCategory)
          ) {
            avaliable = false;
          }

          if (
            filters.categoryMultiple?.length &&
            !filters.categoryMultiple.find((category: string) =>
              item?.category?.categoryName?.includes(category)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.genderMultiple?.length &&
            !filters.genderMultiple.find((gender: string) =>
              item?.category?.categoryName?.includes(gender)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.weightMultiple?.length &&
            !filters.weightMultiple.find((weight: string) =>
              item?.category?.categoryName?.includes(weight)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.trackMultiple?.length &&
            !filters.trackMultiple.find((track: string) =>
              item?.category?.categoryName?.includes(track)
            )
          ) {
            avaliable = false;
          }

          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const [athleteDrag, setAthleteDrag] = useState<any>(null);

  const [fightDrag, setFightDrag] = useState<any>(null);

  const renderFights = useCallback(
    (item: any) => {
      const fightsFiltered = applyFilters(item.fights);

      const itemsSearch = applySearch(fightsFiltered);

      return sortArrayV2(itemsSearch);
    },
    [applyFilters, applySearch]
  );

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        gap: 12,
      }}
    >
      <div style={{ width: '100%' }}>
        {renderObjectDay.map((day, index) => (
          <React.Fragment key={`${index}-${day}`}>
            <div
              style={{
                width: '100%',
                overflowX: 'auto',
                display: 'flex',
                gap: 18,
              }}
            >
              {filterByDay(areasObject, day).map((item, index) => (
                <div key={index} style={{ minWidth: 400, width: 400 }}>
                  <div>{item.name}</div>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      borderRadius: 8,
                      width: 400,
                      minHeight: 100,
                      marginTop: 12,
                      boxSizing: 'border-box',
                      padding: 16,
                      gap: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 500,
                      overflowY: 'auto',
                      marginBottom: 8,
                    }}
                  >
                    {renderFights(item)?.map((fight: any) => (
                      <React.Fragment key={fight.id}>
                        {fight.type === 'presentation' ? (
                          <FightPresentation fight={fight} />
                        ) : (
                          <FightItem
                            fight={fight}
                            reasons={reasonDesclassified}
                            handleChangeAthletes={handleChangeAthletes}
                            handleChangeFight={handleChangeFight}
                            athleteDrag={athleteDrag}
                            setAthleteDrag={setAthleteDrag}
                            fightDrag={fightDrag}
                            setFightDrag={setFightDrag}
                            handleClick={redirect}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AreasFights;

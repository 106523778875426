/* eslint-disable no-plusplus */
import React, { ReactElement, useState } from 'react';
import ScoreboardAreas from './ScoreboardAreas';

interface ShowScoreboardAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
}

const ShowScoreboardAreas: React.FC<ShowScoreboardAreasProps> = ({
  categories,
  startTime,
  eventAreas,
  areas,
  numberDays,
}): ReactElement => {
  const [filters] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
  });

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'left', fontSize: 18 }}>
        Clique na área para iniciar
      </div>

      <ScoreboardAreas
        areas={areas}
        categories={categories}
        filters={filters}
        numberDays={numberDays}
        startTime={startTime}
        eventAreas={eventAreas}
      />
    </div>
  );
};

export default ShowScoreboardAreas;

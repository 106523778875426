import React, { useState } from 'react';
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  IRoundProps,
  IRenderSeedProps,
} from 'react-brackets';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Avatar, Badge, ClickAwayListener, Tooltip } from '@mui/material';
import {
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
} from '../../../../utils/date';

interface LosingProps {
  rounds: IRoundProps[];
  reasonDesclassified: any[];
}

interface ItemTeamProps {
  athlete: any;
  oponent: any;
  seed: any;
  reasonDesclassified: any[];
  backgroundColor?: string;
  nameColor?: string;
  teamColor?: string;
}

const RenderItemTeam = ({
  athlete,
  oponent,
  seed,
  reasonDesclassified,
  backgroundColor = '#2B4755',
  nameColor = '#fff',
  teamColor = '#ccc',
}: ItemTeamProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  const getBadgeIcon = (athleteOne: any, athleteTwo: any) => {
    if (athleteOne?.win) {
      return (
        <CheckCircleIcon
          style={{ width: 16, height: 16 }}
          htmlColor="lightgreen"
        />
      );
    }

    if (athleteOne && athleteTwo?.win) {
      return <CancelIcon style={{ width: 16, height: 16 }} htmlColor="red" />;
    }

    return undefined;
  };

  const getBadgeBorder = (athleteOne: any, athleteTwo: any) => {
    if (athleteOne?.win) {
      return '2px solid lightgreen';
    }

    if (athleteOne && athleteTwo?.win) {
      return '2px solid #FF0000';
    }

    return '2px solid #000';
  };

  const formatName = (name?: string, byeMatch?: boolean) => {
    if (name) {
      return name;
    }

    if (byeMatch) {
      return '-----------';
    }
    return '';
  };

  const getReasonDesclassification = (code: number): React.ReactElement => {
    const reason = reasonDesclassified?.find((item: any) => item.id === code);

    const text = reason?.description || 'Desclassificado';

    return (
      <>
        <b>Atleta desclassificado</b>

        <p>Motivo: {text}</p>
      </>
    );
  };

  return (
    <SeedTeam
      style={{
        justifyContent: 'flex-start',
        gap: 8,
        height: 60,
        background: backgroundColor,
        borderRadius: 4,
        display: 'flex',
        width: 350,
        boxSizing: 'border-box',
        border: '1px solid #ddd',
      }}
    >
      <div style={{ position: 'relative', width: 40 }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={getBadgeIcon(athlete, oponent)}
        >
          <Avatar
            alt={athlete?.name}
            src={athlete?.image}
            sx={{ width: 36, height: 36 }}
            style={{
              border: getBadgeBorder(athlete, oponent),
            }}
          />
        </Badge>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: 'calc(350px - 96px)',
          flex: 1,
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <div
          style={{
            textAlign: 'left',
            width: '300px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: nameColor,
          }}
        >
          {formatName(athlete?.name, seed.bye_match)}
        </div>
        <div
          style={{
            textAlign: 'left',
            color: teamColor,
            fontSize: 12,

            width: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {athlete?.team || '---'}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: 24,
          height: 20,
        }}
      >
        {athlete?.reasonDesclassificationCode && (
          <ClickAwayListener onClickAway={hideTooltip}>
            <Tooltip
              placement="left"
              title={getReasonDesclassification(
                athlete?.reasonDesclassificationCode
              )}
              open={isTooltipVisible}
              arrow
              onClose={hideTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              slotProps={{
                popper: {
                  disablePortal: true,
                },
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
                onMouseEnter={() => setIsTooltipVisible(true)} // Hover para desktop
                onMouseLeave={() => setIsTooltipVisible(false)} // Sair do hover
                onClick={toggleTooltip} // Clique para mobile
              >
                <InfoOutlinedIcon />
              </div>
            </Tooltip>
          </ClickAwayListener>
        )}
      </div>
    </SeedTeam>
  );
};

const WiningBracket: React.FC<LosingProps> = ({
  rounds: wining,
  reasonDesclassified,
}) => {
  const [seedTooltipVisible, setSeedTooltipVisible] = useState('');

  const toggleTooltip = (id: string) => {
    console.log('aqiuuiuiuiu', id);
    setSeedTooltipVisible(id);
  };

  const hideTooltip = () => {
    setSeedTooltipVisible('');
  };

  const RenderSeed = ({ seed, breakpoint }: IRenderSeedProps) => {
    const getTeam = (teams: any[], index: number) => {
      return teams?.find((item) => item.order === index);
    };

    const mapStage = (stage: any): string => {
      const stageOrder: any = {
        'eighth-final': 'Oitavas de final',
        'quarter-final': 'Quartas de final',
        semifinal: 'Semifinal',
        bronze: 'Disputa de Terceiro',
        final: 'Final',
      };

      return stageOrder[stage] || stage;
    };

    const renderTooltip = () => {
      const winReason = seed?.teams?.[0]?.result?.typeVictoryDescription;
      const time = seed?.teams?.[0]?.result?.timeFight;

      const athleteOne = getTeam(seed?.teams, 0);
      const athleteTwo = getTeam(seed?.teams, 1);

      return (
        <div
          style={{
            minWidth: 200,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 8,
            boxSizing: 'border-box',
            fontSize: 16,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>
            {mapStage(seed.stage)}
          </div>
          {Boolean(winReason) && (
            <div style={{}}>
              Vitória por
              <br />
              <span style={{ fontWeight: 'bold' }}>{winReason}</span>
            </div>
          )}
          {Boolean(athleteOne) &&
            Boolean(athleteTwo) &&
            !athleteOne?.reasonDesclassificationCode &&
            !athleteTwo?.reasonDesclassificationCode && (
              <div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Pontos</div>
                  <div>
                    {athleteOne?.result?.points || '0'} -{' '}
                    {athleteTwo?.result?.points || '0'}{' '}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Vantagens</div>
                  <div>
                    {athleteOne?.result?.advantages || '0'} -{' '}
                    {athleteTwo?.result?.advantages || '0'}{' '}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Desvantagens</div>
                  <div>
                    {athleteOne?.result?.faults || '0'} -{' '}
                    {athleteTwo?.result?.faults || '0'}{' '}
                  </div>
                </div>
              </div>
            )}
          {Boolean(seed.finishAt) && (
            <div>Tempo {formatSecondsToMinutesAndSeconds(time || 0)}</div>
          )}
          <div>{seed.finishAt ? formatOnlyHour(seed.finishAt) : 'xx:xx'}</div>
        </div>
      );
    };

    console.log('seedTooltipVisible', seedTooltipVisible);
    console.log('seed.id', seed.id);

    return (
      <Seed
        style={{
          cursor: seed.status === 'completed' ? 'pointer' : '',
        }}
        className="custom-border"
        mobileBreakpoint={breakpoint}
      >
        <ClickAwayListener onClickAway={() => null}>
          <Tooltip
            title={seed.status === 'completed' ? renderTooltip() : undefined}
            arrow
            placement="right"
            open={seedTooltipVisible === seed.id}
            onClose={hideTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            slotProps={{
              popper: {
                disablePortal: true,
              },
            }}
          >
            <div
              onMouseEnter={() => setSeedTooltipVisible(seed.id as string)} // Hover para desktop
              onMouseLeave={() => setSeedTooltipVisible('')} // Sair do hover
              onClick={() => toggleTooltip(seed.id as string)} // Clique para mobile
            >
              <SeedItem
                style={{
                  width: 350,
                  marginTop: 14,
                  background: '#fff',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: -18,
                    bottom: 48,
                    background: '#777',
                    border: '1px solid #ccc',
                    zIndex: 100,
                    fontSize: 10,
                    padding: 4,
                    boxSizing: 'border-box',
                    borderRadius: 12,
                  }}
                >
                  {seed.tag}
                </div>
                <div>
                  <RenderItemTeam
                    athlete={getTeam(seed?.teams, 0)}
                    oponent={getTeam(seed?.teams, 1)}
                    reasonDesclassified={reasonDesclassified}
                    seed={seed}
                  />

                  <div style={{ height: 1, background: '#ccc' }}></div>
                  <RenderItemTeam
                    athlete={getTeam(seed?.teams, 1)}
                    oponent={getTeam(seed?.teams, 0)}
                    reasonDesclassified={reasonDesclassified}
                    seed={seed}
                    backgroundColor="#FFF"
                    nameColor="#000"
                    teamColor="#000"
                  />
                </div>
              </SeedItem>
            </div>
          </Tooltip>
        </ClickAwayListener>
        <div>{!!seed.date && formatOnlyHour(new Date(seed.date))}</div>
      </Seed>
    );
  };

  return (
    <Bracket
      rounds={wining}
      renderSeedComponent={RenderSeed}
      mobileBreakpoint={100}
      swipeableProps={{
        enableMouseEvents: true,
        animateHeight: true,
        style: {
          padding: '0 50px 0 0',
        },
      }}
    />
  );
};

export default WiningBracket;

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import AreasFights from '../ShowAreas/AreasFights';
import AreasCategory from '../ShowAreas/AreasCategory';
import { StyledCustomTabPanel, StyledRoot } from './styles';

interface ListAreasProps {
  areas: any[];
  event: any;
  showInfo?: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <StyledCustomTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </StyledCustomTabPanel>
  );
}

const ListAreas: React.FC<ListAreasProps> = ({
  areas,
  event,
  showInfo = true,
}): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState(0);

  const [daySelected, setDaySelected] = useState('0');

  const [search, setSearch] = useState(searchParams.get('search') || '');

  const searchDebounce = useDebounce(search, 500);

  useEffect(() => {
    if (searchDebounce) {
      setSearchParams({ search: searchDebounce });
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  }, [searchDebounce, searchParams, setSearchParams]);

  const days = useMemo(() => {
    const itemDays = areas.map((item) => item.day);

    const result = [...new Set(itemDays)];

    return result.sort();
  }, [areas]);

  const handleClick = (item: any) => {
    navigate(`brackets/${item.uuid}`);
  };

  const searchRef = useRef<HTMLInputElement>();

  const handleClickSearch = () => {
    if (search) {
      setSearch('');
    }

    // searchRef?.current?.focus();
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  return (
    <StyledRoot>
      <div style={{ textAlign: 'center', fontSize: 18, paddingBottom: 24 }}>
        Áreas
      </div>

      <Box
        sx={{
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 12,
          marginBottom: 4,
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChangeTabValue}
          style={{ display: 'flex' }}
        >
          <Tab
            style={{
              width: 200,
              background: tabValue === 0 ? '#083061' : '#ddd',
              color: tabValue === 0 ? '#fff' : undefined,
              marginRight: 0,
              flex: 1,
            }}
            label="Categorias"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              width: 200,
              background: tabValue === 1 ? '#083061' : '#ddd',
              color: tabValue === 1 ? '#fff' : undefined,
              marginLeft: 0,
              flex: 1,
            }}
            label="Lutas"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <div style={{ display: 'flex', gap: 16 }}>
        <Select
          value={daySelected}
          onChange={(event) => setDaySelected(event.target.value)}
          style={{ width: 320, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          {days.map((item) => (
            <MenuItem key={item} value={item}>
              Dia {item + 1}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="phone"
          inputRef={searchRef}
          variant="outlined"
          placeholder="Nome da categoria ou atleta"
          name="phone"
          style={{ width: 420 }}
          InputLabelProps={{
            style: {
              fontSize: 16,
              textTransform: 'uppercase',
              background: '#fff',
              paddingRight: 5,
              color: '#000',
            },
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickSearch} edge="end">
                {search ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 15 }}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>

      <CustomTabPanel value={tabValue} index={0}>
        <AreasCategory
          filters={{}}
          numberDays={event?.numberDaysFight}
          eventAreas={areas}
          daySelected={daySelected}
          search={searchDebounce}
          redirect={handleClick}
          showInfo={showInfo}
        />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <AreasFights
          filters={{}}
          numberDays={event?.numberDaysFight}
          eventAreas={areas}
          redirect={(fight) => handleClick(fight.category)}
          daySelected={daySelected}
          reasonDesclassified={[]}
          search={searchDebounce}
        />
      </CustomTabPanel>
    </StyledRoot>
  );
};

export default ListAreas;

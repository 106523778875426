/* eslint-disable no-param-reassign */
export const maskPhone = (telefone: string): string => {
  if (!telefone) return '';
  telefone = telefone.replace(/\D/g, '');
  telefone = telefone.replace(/(\d{2})(\d)/, '($1) $2');
  telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2');
  return telefone.substring(0, 16);
};

export const formatPhoneNumberMask = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/\D/g, '');

  const limited = cleaned.slice(0, 11);

  if (limited.length <= 2) {
    return limited;
  }
  if (limited.length <= 6) {
    return `(${limited.slice(0, 2)}) ${limited.slice(2)}`;
  }
  if (limited.length <= 10) {
    return `(${limited.slice(0, 2)}) ${limited.slice(2, 6)}-${limited.slice(
      6,
      10
    )}`;
  }
  return `(${limited.slice(0, 2)}) ${limited.slice(2, 7)}-${limited.slice(
    7,
    11
  )}`;
};

/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useState } from 'react';

import { Box, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AreasFights from './AreasFights';
import CheckinLegend from '../CheckinLegend';
import FilterOrganizeAreaV2 from '../ShowAreas/FilterOrganizeAreaV2';

interface CheckinAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  handleCheckin: (athlete: any) => void;
  reasonDesclassified?: any[];
  changeFilters?: (filters: any) => void;
}

const CheckinAreas: React.FC<CheckinAreasProps> = ({
  categories,
  startTime,
  eventAreas,
  areas,
  filtersData,
  numberDays,
  reasonDesclassified,
  handleCheckin,
  changeFilters,
}): ReactElement => {
  const navigate = useNavigate();

  const [daySelected, setDaySelected] = useState('0');

  const [days, setDays] = useState<number[]>([]);

  const [filters, setFilters] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
    typeCategoryMultiple: [] as string[],
    categoryMultiple: [] as string[],
    trackMultiple: [] as string[],
    weightMultiple: [] as string[],
    genderMultiple: [] as string[],
  });

  useEffect(() => {
    if (changeFilters) {
      changeFilters({ ...filters, daySelected });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected, filters]);

  useEffect(() => {
    const newDays: number[] = [];

    if (eventAreas?.length) {
      eventAreas.forEach((item) => {
        newDays.push(item.day);
      });

      setDays([...new Set(newDays)].sort());
    }
  }, [eventAreas]);

  const handleRedirectFight = (fight: any) => {
    navigate(`/scoreboard/${fight.uuid}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18, paddingBottom: 12 }}>
        Visualizar áreas
      </div>

      {!!filtersData && (
        <div>
          <div>Filtros</div>
          <div>
            <FilterOrganizeAreaV2
              categories={categories}
              filtersData={filtersData}
              handleSubmit={setFilters}
              hasFiltersApplied={Boolean(
                filters.category ||
                  filters.gender ||
                  filters.track ||
                  filters.weight ||
                  filters.typeCategory ||
                  filters.typeCategoryMultiple?.length ||
                  filters.categoryMultiple?.length ||
                  filters.weightMultiple?.length ||
                  filters.trackMultiple?.length ||
                  filters.genderMultiple?.length
              )}
            />
          </div>
        </div>
      )}

      <CheckinLegend
        items={[
          {
            color: '#66bb6b',
            label: 'Checkin realizado',
          },
          {
            color: '#9c27b0',
            label: 'Aguardando checkin',
          },
          {
            color: '#e74c3c',
            label: 'Desclassificado',
          },
        ]}
      />

      <Box
        sx={{
          width: '100%',

          flexDirection: 'column',
        }}
      >
        <div style={{ padding: 24 }}>
          <Select
            value={daySelected}
            onChange={(event) => setDaySelected(event.target.value)}
            style={{ width: 320, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
          >
            {days.map((item, index) => (
              <MenuItem key={index} value={index}>
                Dia {index + 1}
              </MenuItem>
            ))}
          </Select>
        </div>

        <AreasFights
          areas={areas}
          categories={categories}
          filters={filters}
          numberDays={numberDays}
          startTime={startTime}
          eventAreas={eventAreas}
          redirect={handleRedirectFight}
          daySelected={daySelected}
          handleCheckin={handleCheckin}
          reasonDesclassified={reasonDesclassified}
        />
      </Box>
    </div>
  );
};

export default CheckinAreas;

/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import RedoIcon from '@mui/icons-material/Redo';

import { toast } from 'react-toastify';
import { StyledItem } from './styles';

import ModalFinishFight from '../../../pages/private/Scoreboard/ModalFinishFight';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import {
  finishFightRequest,
  getReasonVictoryRequest,
} from '../../../api/scoreboard';
import FightItem from '../../ShowAreas/AreasFights/FightItem';
import FightPresentation from '../../ShowAreas/AreasFights/FightPresentation';

interface AreasFightsProps {
  categories: any[];
  eventArea?: any;
  areas: number;
  numberDays: number;
  reasonDesclassified?: any[];
  redirect: (item: any) => void;
  getData: () => void;
}

const AreasFights: React.FC<AreasFightsProps> = ({
  categories,
  eventArea,
  areas,
  redirect,
  numberDays,
  reasonDesclassified,
  getData,
}): ReactElement => {
  const { authenticate } = useAuthenticate();

  const [areasObject, setAreasObject] = useState<any>(null);

  const [onlyProgramed, setOnlyProgramed] = useState(true);

  const [reasonVictory, setReasonVictory] = useState<any[]>([]);

  const [modalFinish, setModalFinish] = useState({
    open: false,
    athletes: [],
    fightId: '',
    defaultWinner: '',
  });

  const getReasonVictory = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonVictoryRequest(authenticate.token);

        setReasonVictory(data?.data || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token]);

  useEffect(() => {
    getReasonVictory();
  }, [getReasonVictory]);

  const handleOpenModalFinish = (fight: any, order: number): void => {
    const athlete = fight?.athletes?.[order];

    setModalFinish({
      open: true,
      athletes: fight.athletes || [],
      defaultWinner: athlete?.uuid || '',
      fightId: fight.uuid,
    });
  };

  useEffect(() => {
    if (areas > 0 && eventArea && categories?.length) {
      const fights: any[] = [];

      eventArea.categories.forEach((category: any) => {
        category.fights.forEach((fight: any) => {
          fights.push({
            ...fight,
            athletes: fight.athletes?.sort(
              (a: any, b: any) => a.order - b.order
            ),
            category,
          });
        });
      });

      setAreasObject({
        id: `${eventArea.day}-${eventArea.order}`,
        day: eventArea.day,
        name: eventArea.name,
        categories: [],
        fights,
      });
    }
  }, [areas, eventArea, categories, numberDays]);

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array?.length) return [];
    return array.sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();

      if (timeA !== timeB) {
        return timeA - timeB;
      }

      return a.order - b.order;
    });
  };

  const handleFilterFights = useMemo(() => {
    if (!onlyProgramed) {
      const result = sortArrayV2(areasObject?.fights);

      localStorage.setItem(
        'allFights',
        JSON.stringify(
          result
            .filter((item) => item.status === 'programed')
            .map((item) => item.uuid)
        )
      );

      return result;
    }
    const items = areasObject?.fights?.filter((item: any) => {
      return !['completed', 'bye', 'pending', 'canceled'].includes(item.status);
    });

    const result = sortArrayV2(items);

    localStorage.setItem(
      'allFights',
      JSON.stringify(result.map((item) => item.uuid))
    );

    return result;
  }, [areasObject?.fights, onlyProgramed]);

  const firstFightToScoreboard = useMemo(() => {
    return handleFilterFights.find(
      (item) => !['completed', 'bye', 'canceled'].includes(item.status)
    );
  }, [handleFilterFights]);

  const handleRedirect = (fight: any) => {
    if (fight.type === 'fight' && !fight.athletes?.length) {
      return undefined;
    }

    if (['completed', 'pending'].includes(fight.status)) {
      return undefined;
    }

    if (fight.uuid !== firstFightToScoreboard?.uuid) {
      return undefined;
    }

    return () => redirect(fight);
  };

  const handleSubmitFinish = async (values: any) => {
    try {
      if (authenticate.token && modalFinish.fightId) {
        const reason = reasonVictory.find((item) => item.id === values.reason);

        const data = {
          ...values,
          reasonDescription: reason.description,
          timeFight: 0,
        };

        await finishFightRequest(authenticate.token, modalFinish.fightId, data);

        toast('Atleta avançado com sucesso!', { type: 'success' });
        setModalFinish({ ...modalFinish, open: false });

        getData();
      }
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div style={{ textAlign: 'left', fontSize: 30, paddingBottom: 12 }}>
              Dia {areasObject?.day + 1} {'>'}{' '}
              <span style={{ textDecoration: 'underline' }}>
                {' '}
                {areasObject?.name}
              </span>
            </div>

            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onlyProgramed}
                      onChange={(e) => setOnlyProgramed(e.target.checked)}
                    />
                  }
                  label="Somente programadas"
                />
              </FormGroup>
            </div>
            {!handleFilterFights.length && !!areasObject?.fights && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  flex: 1,
                  padding: 24,
                  gap: 24,
                  border: '1px solid #ccc',
                  borderRadius: 8,
                  marginTop: 12,
                }}
              >
                <div>Nenhuma luta programada.</div>
                <Button
                  variant="contained"
                  onClick={() => setOnlyProgramed(false)}
                >
                  Ver todas as lutas
                </Button>
              </div>
            )}

            <div
              style={{
                borderRadius: 8,
                width: '100%',
                minHeight: 100,
                height: 'auto',
                marginTop: 12,
                boxSizing: 'border-box',
                gap: 8,
                display: handleFilterFights?.length ? 'flex' : 'none',
                flexDirection: 'column',
                maxWidth: 450,
              }}
            >
              {handleFilterFights?.map((fight: any) => (
                <React.Fragment key={fight.id}>
                  {fight.type === 'presentation' ? (
                    <StyledItem
                      status={fight.status}
                      onClick={handleRedirect(fight)}
                    >
                      {handleRedirect(fight) && (
                        <div
                          style={{
                            padding: '8px 8px 24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button variant="contained">
                            Iniciar apresentação
                          </Button>
                        </div>
                      )}
                      <FightPresentation fight={fight} />
                    </StyledItem>
                  ) : (
                    <StyledItem
                      status={fight.status}
                      onClick={handleRedirect(fight)}
                    >
                      {handleRedirect(fight) && (
                        <div
                          style={{
                            padding: '8px 8px 24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button variant="contained">Iniciar placar</Button>
                        </div>
                      )}
                      <FightItem
                        fight={fight}
                        reasons={reasonDesclassified}
                        action={
                          fight?.athletes?.length > 1 &&
                          fight.status === 'programed'
                            ? {
                                action: (athlete) =>
                                  handleOpenModalFinish(fight, athlete.order),
                                icon: <RedoIcon />,
                                label: 'Avançar atleta',
                                isDisabled: (athlete) =>
                                  Boolean(athlete.reasonDesclassificationCode),
                              }
                            : undefined
                        }
                      />
                    </StyledItem>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ModalFinishFight
        open={modalFinish.open}
        title="Avançar atleta"
        defaultWinner={modalFinish.defaultWinner}
        handleBack={() => setModalFinish({ ...modalFinish, open: false })}
        reasonVictory={reasonVictory}
        backButtonText="Cancelar"
        hiddenWithoutWinner
        handleSubmit={handleSubmitFinish}
        athletes={modalFinish?.athletes?.sort(
          (a: any, b: any) => a.order - b.order
        )}
      />
    </React.Fragment>
  );
};

export default AreasFights;

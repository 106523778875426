import React from 'react';
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  IRoundProps,
  IRenderSeedProps,
} from 'react-brackets';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Avatar, Badge, Tooltip } from '@mui/material';
import {
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
} from '../../../../utils/date';

interface LosingProps {
  rounds: IRoundProps[];
  reasonDesclassified: any[];
}

const WiningBracket: React.FC<LosingProps> = ({
  rounds: wining,
  reasonDesclassified,
}) => {
  const RenderSeed = ({ seed, breakpoint }: IRenderSeedProps) => {
    const formatName = (name?: string, byeMatch?: boolean) => {
      if (name) {
        return name;
      }

      if (byeMatch) {
        return '-----------';
      }
      return '';
    };

    const getTeam = (teams: any[], index: number) => {
      return teams?.find((item) => item.order === index);
    };

    const mapStage = (stage: any): string => {
      const stageOrder: any = {
        'eighth-final': 'Oitavas de final',
        'quarter-final': 'Quartas de final',
        semifinal: 'Semifinal',
        bronze: 'Disputa de Terceiro',
        final: 'Final',
      };

      return stageOrder[stage] || stage;
    };

    const renderTooltip = () => {
      const winReason = seed?.teams?.[0]?.result?.typeVictoryDescription;
      const time = seed?.teams?.[0]?.result?.timeFight;

      const athleteOne = getTeam(seed?.teams, 0);
      const athleteTwo = getTeam(seed?.teams, 1);

      return (
        <div
          style={{
            minWidth: 200,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 8,
            boxSizing: 'border-box',
            fontSize: 16,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>
            {mapStage(seed.stage)}
          </div>
          {Boolean(winReason) && (
            <div style={{}}>
              Vitória por
              <br />
              <span style={{ fontWeight: 'bold' }}>{winReason}</span>
            </div>
          )}
          {Boolean(athleteOne) &&
            Boolean(athleteTwo) &&
            !athleteOne?.reasonDesclassificationCode &&
            !athleteTwo?.reasonDesclassificationCode && (
              <div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Pontos</div>
                  <div>
                    {athleteOne?.result?.points || '0'} -{' '}
                    {athleteTwo?.result?.points || '0'}{' '}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Vantagens</div>
                  <div>
                    {athleteOne?.result?.advantages || '0'} -{' '}
                    {athleteTwo?.result?.advantages || '0'}{' '}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Desvantagens</div>
                  <div>
                    {athleteOne?.result?.faults || '0'} -{' '}
                    {athleteTwo?.result?.faults || '0'}{' '}
                  </div>
                </div>
              </div>
            )}
          {Boolean(seed.finishAt) && (
            <div>Tempo {formatSecondsToMinutesAndSeconds(time || 0)}</div>
          )}
          <div>{seed.finishAt ? formatOnlyHour(seed.finishAt) : 'xx:xx'}</div>
        </div>
      );
    };

    const getReasonDesclassification = (code: number): React.ReactElement => {
      const reason = reasonDesclassified?.find((item) => item.id === code);

      const text = reason?.description || 'Desclassificado';

      return (
        <>
          <b>Atleta desclassificado</b>

          <p>Motivo: {text}</p>
        </>
      );
    };

    const getBadgeIcon = (athleteOne: any, athleteTwo: any) => {
      if (athleteOne?.win) {
        return (
          <CheckCircleIcon
            style={{ width: 16, height: 16 }}
            htmlColor="lightgreen"
          />
        );
      }

      if (athleteOne && athleteTwo?.win) {
        return <CancelIcon style={{ width: 16, height: 16 }} htmlColor="red" />;
      }

      return undefined;
    };

    const getBadgeBorder = (athleteOne: any, athleteTwo: any) => {
      if (athleteOne?.win) {
        return '2px solid lightgreen';
      }

      if (athleteOne && athleteTwo?.win) {
        return '2px solid #FF0000';
      }

      return '2px solid #000';
    };

    return (
      <Seed
        style={{
          cursor: seed.status === 'completed' ? 'pointer' : '',
        }}
        className="custom-border"
        mobileBreakpoint={breakpoint}
      >
        <Tooltip
          title={seed.status === 'completed' ? renderTooltip() : undefined}
          arrow
          placement="right"
        >
          <SeedItem
            style={{
              width: 350,
              marginTop: 14,
              background: '#fff',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: -18,
                bottom: 48,
                background: '#777',
                border: '1px solid #ccc',
                zIndex: 100,
                fontSize: 10,
                padding: 4,
                boxSizing: 'border-box',
                borderRadius: 12,
              }}
            >
              {seed.tag}
            </div>
            <div>
              <SeedTeam
                style={{
                  justifyContent: 'flex-start',
                  gap: 8,
                  height: 60,
                  background: '#2B4755',
                  borderRadius: 4,
                  display: 'flex',
                  width: 350,
                  boxSizing: 'border-box',
                }}
              >
                <div style={{ position: 'relative', width: 40 }}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={getBadgeIcon(
                      getTeam(seed?.teams, 0),
                      getTeam(seed?.teams, 1)
                    )}
                  >
                    <Avatar
                      alt={getTeam(seed?.teams, 0)?.name}
                      src={getTeam(seed?.teams, 0)?.image}
                      sx={{ width: 36, height: 36 }}
                      style={{
                        border: getBadgeBorder(
                          getTeam(seed?.teams, 0),
                          getTeam(seed?.teams, 1)
                        ),
                      }}
                    />
                  </Badge>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: 'calc(350px - 96px)',
                    flex: 1,
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'left',
                      width: '300px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {formatName(getTeam(seed?.teams, 0)?.name, seed.bye_match)}
                  </div>
                  <div
                    style={{
                      textAlign: 'left',
                      color: '#ccc',
                      fontSize: 12,

                      width: '200px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getTeam(seed?.teams, 0)?.team || '---'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: 24,
                    height: 20,
                  }}
                >
                  {getTeam(seed?.teams, 0)?.reasonDesclassificationCode && (
                    <Tooltip
                      placement="left"
                      title={getReasonDesclassification(
                        getTeam(seed?.teams, 0)?.reasonDesclassificationCode
                      )}
                      arrow
                    >
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <InfoOutlinedIcon />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </SeedTeam>
              <div style={{ height: 1, background: '#ccc' }}></div>
              <SeedTeam
                style={{
                  justifyContent: 'flex-start',
                  gap: 8,
                  borderRadius: 4,
                  height: 60,
                  background: '#FFF',
                  width: 350,
                  boxSizing: 'border-box',
                }}
              >
                <div style={{ position: 'relative', width: 40 }}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={getBadgeIcon(
                      getTeam(seed?.teams, 1),
                      getTeam(seed?.teams, 0)
                    )}
                  >
                    <Avatar
                      alt={getTeam(seed?.teams, 1)?.name}
                      src={getTeam(seed?.teams, 1)?.image}
                      sx={{ width: 36, height: 36 }}
                      style={{
                        border: getBadgeBorder(
                          getTeam(seed?.teams, 1),
                          getTeam(seed?.teams, 0)
                        ),
                      }}
                    />
                  </Badge>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: 'calc(350px - 96px)',
                    flex: 1,
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'left',
                      width: '300px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      color: '#000',
                    }}
                  >
                    {formatName(getTeam(seed?.teams, 1)?.name, seed.bye_match)}
                  </div>
                  <div
                    style={{
                      textAlign: 'left',
                      color: '#000',
                      fontSize: 12,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getTeam(seed?.teams, 1)?.team || '---'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: 24,
                    height: 20,
                  }}
                >
                  {getTeam(seed?.teams, 1)?.reasonDesclassificationCode && (
                    <Tooltip
                      placement="left"
                      title={getReasonDesclassification(
                        getTeam(seed?.teams, 1)?.reasonDesclassificationCode
                      )}
                      arrow
                    >
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <InfoOutlinedIcon htmlColor="#2B4755" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </SeedTeam>
            </div>
          </SeedItem>
        </Tooltip>
        <div>{!!seed.date && formatOnlyHour(new Date(seed.date))}</div>
      </Seed>
    );
  };

  return (
    <Bracket
      rounds={wining}
      renderSeedComponent={RenderSeed}
      mobileBreakpoint={100}
      swipeableProps={{
        enableMouseEvents: true,
        animateHeight: true,
        style: {
          padding: '0 50px 0 0',
        },
      }}
    />
  );
};

export default WiningBracket;

import styled from 'styled-components';

export const StyledRoot = styled('div')({
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  boxSizing: 'border-box',
  padding: '0px',
  '@media (min-width: 900px) ': {
    padding: 32,
  },
});

import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { StyledRoot } from './styles';
import LoginForm from '../../../components/LoginFormV2';
import { LoginFormProps } from '../../../components/LoginFormV2/schema';
import { sendToken, verifyToken } from '../../../api/authenticate';
import { removeMask } from '../../../utils/mask';
import VerifyTokenForm from '../../../components/VerifyTokenForm';
import { VerifyTokenFormProps } from '../../../components/VerifyTokenForm/schema';

const Login = (): React.ReactElement => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSentToken, setSentToken] = React.useState(false);

  const [phone, setPhone] = React.useState('');

  const { authenticate, signin } = useAuthenticate();

  React.useEffect(() => {
    if (authenticate?.isAuthenticate) {
      navigate('/dash/events');
    }
  }, [authenticate, navigate]);

  const handleSubmit = async (values: LoginFormProps) => {
    try {
      setIsLoading(true);

      setPhone(values.phone);

      const phone = removeMask(values.phone);

      await sendToken({ phone });

      setSentToken(true);
    } catch (error: any) {
      const message = 'Celular não encontrado!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitVerifyToken = async (values: VerifyTokenFormProps) => {
    try {
      setIsLoading(true);

      const phoneValue = removeMask(phone);

      const data = await verifyToken({
        phone: phoneValue,
        token: values.token,
      });

      signin(data.token);
    } catch (error: any) {
      const message = 'Código inválido!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledRoot>
      {isSentToken ? (
        <VerifyTokenForm
          phone={phone}
          onSubmit={handleSubmitVerifyToken}
          isLoading={isLoading}
        />
      ) : (
        <LoginForm onSubmit={handleSubmit} isLoading={isLoading} />
      )}
    </StyledRoot>
  );
};

export default Login;

import styled from 'styled-components';

export const StyledRoot = styled.span`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const StyledDrawer = styled('div')({
  maxWidth: 275,
  minHeight: '100vh',
  '@media (min-width: 900px) ': {
    width: 275,
  },
});

export const StyledFightOne = styled('div')({
  flex: 1,
  display: 'flex',

  background: '#2B4755',
  padding: 24,
  boxSizing: 'border-box',
  color: '#FFF',
});

export const StyledFightTwo = styled('div')({
  display: 'flex',
  flex: 1,

  padding: 24,
  boxSizing: 'border-box',
});

export const StyledLeft = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

export const StyledRight = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  gap: 12,
});

export const StyledContentTime = styled('div')({
  flex: 1,
  display: 'flex',
  background: '#333',
});

export const StyledContentName = styled('div')({
  display: 'flex',

  flexDirection: 'column',
  gap: 24,
});

export const StyledName = styled('div')({
  display: 'flex',

  fontSize: 52,
  fontWeight: 600,
});

export const StyledTeam = styled('div')({
  display: 'flex',
  fontSize: 32,
});

export const StyledContentScore = styled('div')<{ color?: string }>(
  ({ color }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    background: color,
  })
);

export const StyledContentNumber = styled('div')<{ color?: string }>(
  ({ color }) => ({
    width: 150,
    height: 150,
    fontSize: 100,
    fontWeight: 600,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: color,
    borderRadius: 8,
  })
);

export const StyledContentControl = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 12,
  fontSize: 28,
});

export const StyledContentControlButton = styled('div')({
  background: 'gray',
  width: 40,
  height: 40,
  borderRadius: 40,
  color: '#fff',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
});

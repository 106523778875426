/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import { Divider, Tooltip } from '@mui/material';
import { StyledItem } from './styles';
import {
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
} from '../../../../utils/date';
import AthleteItem from '../AthleteItem';

interface FightItemProps {
  fight: any;
  action?: {
    icon: React.ReactElement;
    action: (athlete: any) => void;
    label: string;
    isDisabled?: (athlete: any) => boolean;
  };
  mapBackgroundByStatus?: (status: string) => string;
  reasons?: any[];
  handleChangeAthletes?: (athlete: any, origin: any) => void;
  handleChangeFight?: (fight: any, origin: any) => void;
  athleteDrag?: any;
  setAthleteDrag?: (athlete: any) => void;
  fightDrag?: any;
  setFightDrag?: (athlete: any) => void;
  handleClick?: (fight: any) => void;
}

const FightItem: React.FC<FightItemProps> = ({
  fight,
  action,
  reasons,
  mapBackgroundByStatus,
  handleChangeAthletes,
  handleChangeFight,
  athleteDrag,
  handleClick,
  setAthleteDrag,
  fightDrag,
  setFightDrag,
}): ReactElement => {
  const mapStage = (stage: any): string => {
    const stageOrder: any = {
      'eighth-final': 'Oitavas de final',
      'quarter-final': 'Quartas de final',
      semifinal: 'Semifinal',
      bronze: 'Disputa de Terceiro',
      final: 'Final',
    };

    return stageOrder[stage] || stage;
  };

  const getAthlete = (athletes: any[], order: number): any => {
    return athletes.find((item) => item.order === order);
  };

  const mapStatusFight = (
    status: any
  ): { label: string; color: string; background: string } => {
    const options: any = {
      pending: { label: 'Aguardando checkin', background: '#ffa727' },
      canceled: {
        label: 'Ambos desclassificados',
        color: '#fff',
        background: '#404243',
      },

      default: { label: 'Confirmada', color: '#000', background: '#66bb6b' },
    };

    return options[status] || options.default;
  };

  const mapStatus = (
    status: any
  ): { label: string; color: string; background: string } => {
    const options: any = {
      pending: { label: 'A confirmar', color: '#fff', background: '#ffa727' },
      completed: { label: 'Finalizada', color: '#000', background: '#66bb6b' },

      canceled: { label: 'Cancelada', color: '#fff', background: '#404243' },
      programed: { label: 'Programada', color: '#000', background: '#66bb6b' },
      stopped: {
        label: 'Aguardando resultado',
        color: '#000',
        background: '#ffa727',
      },
      in_progress: {
        label: 'Em andamento',
        color: '#000',
        background: '#29b6f6',
      },

      bye: {
        label: 'BYE',
        color: '#000',
        background: '#8bc34a',
      },
    };

    return options[status] || {};
  };

  const onDragStart =
    (fight: any, athlete: number) => (event: React.DragEvent) => {
      if (setAthleteDrag) {
        setAthleteDrag(athlete);
      }
      event.dataTransfer.setData('athlete', JSON.stringify(athlete));
    };

  const onDragFightStart = (fight: any) => (event: React.DragEvent) => {
    if (setFightDrag) {
      setFightDrag(fight);
    }
    event.dataTransfer.setData('fight', JSON.stringify(fight));
  };

  const allowDrop = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const onDragEnd = (event: React.DragEvent) => {
    if (setAthleteDrag) {
      setAthleteDrag(null);
    }

    if (setFightDrag) {
      setFightDrag(null);
    }

    event.preventDefault();
  };

  const onDrop = (athlete: any) => (event: React.DragEvent) => {
    if (!athlete) {
      return;
    }

    const data = event.dataTransfer.getData('athlete');

    const athleteOrigin = JSON.parse(data);

    if (athlete.id === athleteOrigin.id) {
      return;
    }

    if (athlete?.category !== athleteOrigin.category) {
      return;
    }

    if (handleChangeAthletes) {
      handleChangeAthletes(athleteOrigin, athlete);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const onDropFight = (fight: any) => (event: React.DragEvent) => {
    if (!fight || fight?.status === 'completed') {
      return;
    }

    const data = event.dataTransfer.getData('fight');
    const fightOrigin = JSON.parse(data);

    if (fight.id === fightOrigin.id) {
      return;
    }

    if (fight?.category?.category !== fightOrigin?.category?.category) {
      return;
    }

    if (handleChangeFight) {
      handleChangeFight(fightOrigin, fight);
    }

    event.preventDefault();
  };

  const renderTooltip = () => {
    const winReason = fight?.athletes?.[0]?.result?.typeVictoryDescription;
    const time = fight?.athletes?.[0]?.result?.timeFight;

    const athleteOne = getAthlete(fight.athletes, 0);
    const athleteTwo = getAthlete(fight.athletes, 1);

    return (
      <div
        style={{
          minWidth: 200,
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          padding: 8,
          boxSizing: 'border-box',
          fontSize: 16,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 'bold' }}>
          {mapStage(fight.stage)}
        </div>

        {Boolean(winReason) && (
          <div style={{}}>
            Vitória por
            <br />
            <span style={{ fontWeight: 'bold' }}>{winReason}</span>
          </div>
        )}

        {Boolean(athleteOne) &&
          Boolean(athleteTwo) &&
          !athleteOne?.reasonDesclassificationCode &&
          !athleteTwo?.reasonDesclassificationCode && (
            <div>
              <div style={{ display: 'flex', gap: 8 }}>
                <div>Pontos</div>
                <div>
                  {athleteOne?.result?.points || '0'} -{' '}
                  {athleteTwo?.result?.points || '0'}{' '}
                </div>
              </div>
              <div style={{ display: 'flex', gap: 8 }}>
                <div>Vantagens</div>
                <div>
                  {athleteOne?.result?.advantages || '0'} -{' '}
                  {athleteTwo?.result?.advantages || '0'}{' '}
                </div>
              </div>
              <div style={{ display: 'flex', gap: 8 }}>
                <div>Desvantagens</div>
                <div>
                  {athleteOne?.result?.faults || '0'} -{' '}
                  {athleteTwo?.result?.faults || '0'}{' '}
                </div>
              </div>
            </div>
          )}

        {Boolean(fight.finishAt) && (
          <div>Tempo {formatSecondsToMinutesAndSeconds(time || 0)}</div>
        )}

        {Boolean(fight.finishAt) && (
          <div>{`Finalizou às ${formatOnlyHour(fight.finishAt)}`}</div>
        )}
      </div>
    );
  };

  return (
    <Tooltip
      title={fight?.status === 'completed' ? renderTooltip() : ''}
      arrow
      placement="right"
    >
      <StyledItem
        key={fight.id}
        status={fight.status}
        draggable={Boolean(handleChangeFight) && fight.status !== 'completed'}
        allowdropathlete={
          (athleteDrag?.category === fight?.category?.category &&
            athleteDrag?.stage === fight?.stage &&
            fight.status !== 'completed') ||
          (fightDrag?.category?.category === fight?.category?.category &&
            fightDrag?.stage === fight?.stage &&
            fight.status !== 'completed')
        }
        onDragStart={onDragFightStart(fight)}
        onDragEnd={onDragEnd}
        onDragOver={fightDrag ? allowDrop : undefined}
        onDrop={onDropFight(fight)}
        onClick={() => handleClick?.(fight)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}
            >
              <Tooltip title={mapStatusFight(fight.status).label} arrow>
                <div
                  style={{
                    width: 12,
                    height: 12,
                    background: mapStatusFight(fight.status).background,
                    borderRadius: 12,
                  }}
                />
              </Tooltip>
              <div style={{ fontWeight: 'bold' }}>
                {formatOnlyHour(fight.date)}
              </div>
              <div>
                {mapStage(fight.stage)}{' '}
                {!['final', 'bronze'].includes(fight.stage)
                  ? fight.order + 1
                  : ''}
                {fight.area ? ` - ${fight.area?.name}` : ''}
              </div>
            </div>
            <div
              style={{
                fontSize: 12,
              }}
            >
              {fight.category?.categoryName}
            </div>
            {fight?.result?.typeVictoryDescription && (
              <div
                style={{
                  fontSize: 12,
                  color: '#4caf50',
                }}
              >
                {fight?.result?.typeVictoryDescription}
              </div>
            )}
          </div>
          <div
            style={{
              padding: '4px 8px',
              boxSizing: 'border-box',
              borderRadius: 8,
              fontSize: 12,
              background: mapStatus(fight?.status)?.background,
              color: mapStatus(fight?.status)?.color,
            }}
          >
            {mapStatus(fight?.status)?.label}
          </div>
        </div>

        <div
          style={{
            margin: '4px 0px',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          <AthleteItem
            athlete={getAthlete(fight.athletes, 0)}
            color="#fff"
            background={
              mapBackgroundByStatus
                ? mapBackgroundByStatus(getAthlete(fight.athletes, 0)?.status)
                : '#2B4755'
            }
            action={action}
            reasons={reasons}
            draggable={
              Boolean(handleChangeAthletes) &&
              fight.status !== 'completed' &&
              Boolean(getAthlete(fight.athletes, 0))
            }
            onDragStart={onDragStart(fight, getAthlete(fight.athletes, 0))}
            onDragOver={athleteDrag ? allowDrop : undefined}
            onDragEnd={onDragEnd}
            onDragLeave={() => null}
            onDrop={onDrop(getAthlete(fight.athletes, 0))}
          />

          {mapBackgroundByStatus && <Divider />}

          <AthleteItem
            athlete={getAthlete(fight.athletes, 1)}
            color={mapBackgroundByStatus ? '#fff' : undefined}
            background={
              mapBackgroundByStatus
                ? mapBackgroundByStatus(getAthlete(fight.athletes, 1)?.status)
                : undefined
            }
            action={action}
            reasons={reasons}
            draggable={
              Boolean(handleChangeAthletes) &&
              fight.status !== 'completed' &&
              Boolean(getAthlete(fight.athletes, 1))
            }
            onDragStart={onDragStart(fight, getAthlete(fight.athletes, 1))}
            onDragOver={athleteDrag ? allowDrop : undefined}
            onDragEnd={onDragEnd}
            onDragLeave={() => null}
            onDrop={onDrop(getAthlete(fight.athletes, 1))}
          />
        </div>
      </StyledItem>
    </Tooltip>
  );
};

export default FightItem;

import React from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import ReactDOM from 'react-dom';

import { ThemeProvider } from '@mui/material';
import App from './App';
import Connect from './Connect';

import * as serviceWorker from './serviceWorkerRegistration';
import { theme } from './theme';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <RecoilNexus />
        <Connect>
          <App />
        </Connect>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();

import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { StyledRoot } from './styles';
import HeaderILutas from '../EventAreas/HeaderILutas';

const PublicPage: React.FC = (): ReactElement => {
  return (
    <StyledRoot>
      <HeaderILutas />
      <Outlet />
    </StyledRoot>
  );
};

export default PublicPage;

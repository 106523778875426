import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';

import { IconButton } from '@mui/material';
import {
  StyledChangeEvent,
  StyledChangeEventButton,
  StyledContainer,
  StyledDrawer,
  StyledHeader,
  StyledHeaderButtonDrawer,
  StyledOutlet,
  StyledRoot,
} from './styles';
import DrawerMenu from '../../../components/DrawerMenu';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { getEvent } from '../../../api/events';
import useEvent from '../../../recoil/hooks/event';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { StyledMessage } from '../Events/styles';

const Dashboard: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const width = useWindowWidth();

  const { eventId } = useParams();

  const { event, setEvent } = useEvent();

  const [openDrawer, setOpenDrawe] = useState(false);

  const [openDrawerDesktop, setOpenDrawerDesktop] = useState(true);

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && eventId) {
        const data = await getEvent(authenticate.token, eventId);

        setEvent(data?.event);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleChangeDrawer = () => {
    if (width > 900) {
      setOpenDrawerDesktop((previewValue) => !previewValue);
    } else {
      setOpenDrawe((previewValue) => !previewValue);
    }
  };

  const drawerMenuItems = useMemo(() => {
    const items = [];

    const isStaff = authenticate?.user?.role === 'S';
    const isMesario = authenticate?.user?.role === 'M';
    const isCheckin = authenticate?.user?.role === 'C';
    const isPremiacao = authenticate?.user?.role === 'P';
    const isEstatisticas = authenticate?.user?.role === 'E';

    if (isStaff) {
      items.push({
        label: 'Evento',
        icon: <EventIcon />,
        path: 'home',
      });
      items.push({
        label: 'Áreas',
        icon: <SpaceDashboardIcon />,
        path: 'areas',
      });
    }

    if (isStaff || isCheckin) {
      items.push({
        label: 'Checkin',
        icon: <WhereToVoteIcon />,
        path: 'checkin',
      });
    }

    if (isStaff || isMesario) {
      items.push({
        label: 'Placar',
        icon: <ScoreboardIcon />,
        path: 'scoreboard',
      });
    }

    if (isStaff || isPremiacao) {
      items.push({
        label: 'Premiação',
        icon: <WorkspacePremiumIcon />,
        path: 'awards',
      });
    }

    if (isStaff || isEstatisticas) {
      items.push({
        label: 'Estatísticas',
        icon: <InsertChartIcon />,
        path: 'statistics',
      });
    }

    return items;
  }, [authenticate?.user?.role]);

  return (
    <StyledRoot>
      <StyledDrawer open={openDrawer || openDrawerDesktop}>
        <DrawerMenu
          open={openDrawer}
          onClose={() => setOpenDrawe(false)}
          variant={width > 900 && openDrawerDesktop ? 'permanent' : 'temporary'}
          options={drawerMenuItems}
        />
      </StyledDrawer>
      <StyledContainer>
        <StyledHeader>
          <StyledHeaderButtonDrawer>
            <IconButton onClick={handleChangeDrawer}>
              <MenuIcon />
            </IconButton>
            <StyledMessage>
              Bem vindo, {authenticate?.user?.name || 'amigo'}
            </StyledMessage>
          </StyledHeaderButtonDrawer>
          <StyledChangeEvent>
            {event?.name}
            <StyledChangeEventButton onClick={() => navigate('/dash/events')}>
              Alterar
            </StyledChangeEventButton>
          </StyledChangeEvent>
        </StyledHeader>
        <StyledOutlet>
          <Outlet />
        </StyledOutlet>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Dashboard;

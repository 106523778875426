import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import { StyledDialogContent } from './styles';

interface Props {
  open: boolean;
  message?: string;
}

const ModalLoading: React.FC<Props> = ({
  open,
  message = 'Carregando',
}): React.ReactElement => {
  return (
    <Dialog open={open}>
      <StyledDialogContent>
        <CircularProgress />
        <div>{message}</div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default ModalLoading;

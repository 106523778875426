import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledItemCategory = styled(Box)({
  width: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 10px',
  boxSizing: 'border-box',
  transition: '0.4s',
  cursor: 'pointer',

  '&:hover': {
    background: '#083061aa',
    color: '#fff',
  },

  '&:active': {
    background: '#083061',
    color: '#fff',
  },
});

interface StyledItemAreaProps {
  scroll?: 'yes' | 'no';
}

export const StyledItemArea = styled('div')<StyledItemAreaProps>(
  ({ scroll }) => ({
    border: '1px solid #ccc',
    borderRadius: 8,
    width: 450,
    minHeight: 100,
    maxHeight: scroll === 'yes' ? 'calc(100vh - 162px)' : 'auto',
    overflowY: 'auto',
    marginTop: 12,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '0.4s',
  })
);

export const StyledArea = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  gap: 12,
  boxSizing: 'border-box',
  transition: '0.4s',
  '&:hover': {
    borderRadius: 8,
    background: '#f1f1f1',
  },
});

/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import { Chart } from 'react-google-charts';

import {
  StyledCard,
  StyledContentCards,
  StyledCount,
  StyledNotFound,
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableColumn,
  StyledTableHeader,
  StyledTableRow,
  StyledTitle,
} from './styles';
import {
  calcDiffInSeconds,
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
} from '../../utils/date';

interface StatisticsReportsProps {
  data?: any;
}

const StatisticsReports: React.FC<StatisticsReportsProps> = ({
  data,
}): ReactElement => {
  const mapStage = (stage: any): string => {
    const stageOrder: any = {
      'eighth-final': 'Oitavas de final',
      'quarter-final': 'Quartas de final',
      semifinal: 'Semifinal',
      bronze: 'Terceiro lugar',
      final: 'Final',
    };

    return stageOrder[stage] || stage;
  };

  return (
    <StyledRoot>
      <StyledContentCards>
        <StyledCard>
          <div>Áreas</div>
          <StyledCount>{data?.numberOfAreas || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Atletas</div>
          <StyledCount>{data?.numberOfAthletes || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Atletas de bye</div>
          <StyledCount>{data?.numberOfAthletesBye || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Categorias</div>
          <StyledCount>{data?.numberOfCategories || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Categorias finalizadas</div>
          <StyledCount>{data?.numberOfCategoriesCompleted || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas</div>
          <StyledCount>{data?.numberOfFights || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas finalizadas</div>
          <StyledCount>{data?.numberOfFightsCompleted || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas canceladas</div>
          <StyledCount>{data?.numberOfFightsCanceled || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas programadas</div>
          <StyledCount>{data?.numberOfFightsProgramed || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas pendentes de checkin</div>
          <StyledCount>{data?.numberOfFightsPending || '0'}</StyledCount>
        </StyledCard>
      </StyledContentCards>

      <div>
        <StyledTitle>Duração das Categorias</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Inicio</StyledTableColumn>
            <StyledTableColumn>Fim</StyledTableColumn>
            <StyledTableColumn>Duração</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.categoriesTiming?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumn>
                  {item.categoryName || '--'}
                </StyledTableColumn>
                <StyledTableColumn>
                  {item.start ? formatOnlyHour(item.start) : '--'}
                </StyledTableColumn>
                <StyledTableColumn>
                  {item.start ? formatOnlyHour(item.finish) : '--'}
                </StyledTableColumn>
                {Boolean(item.finish) && Boolean(item.start) && (
                  <StyledTableColumn>
                    {formatSecondsToMinutesAndSeconds(
                      calcDiffInSeconds(item.finish, item.start)
                    )}
                  </StyledTableColumn>
                )}
              </StyledTableRow>
            ))}

            {!data?.categoriesTiming?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Atletas</StyledTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {Boolean(data?.countAthletesDesclassified?.length) && (
            <div style={{ minWidth: 500 }}>
              <Chart
                chartType="PieChart"
                data={[
                  ['Motivo', 'Quantidade'],
                  ...data?.countAthletesDesclassified?.map((item: any) => {
                    return [item.reason, item.count];
                  }),
                ]}
                options={{ title: 'Atletas por tipo de desclassifição' }}
                width={'100%'}
                height={'300px'}
              />
            </div>
          )}

          {Boolean(data?.countAthletesWin?.length) && (
            <div style={{ minWidth: 500 }}>
              <Chart
                chartType="PieChart"
                data={[
                  ['Motivo', 'Quantidade'],
                  ...data?.countAthletesWin?.map((item: any) => {
                    return [item.typeVictoryDescription, item.count];
                  }),
                ]}
                options={{ title: 'Atletas por tipo de vitoria' }}
                width={'100%'}
                height={'300px'}
              />
            </div>
          )}
        </div>
      </div>

      <div>
        <StyledTitle>Lutas que mais pontuaram</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Fase</StyledTableColumn>
            <StyledTableColumn>Pontos</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.topFightsPoints?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumn>
                  {item?.eventAreaCategory?.categoryName || '--'}
                </StyledTableColumn>
                <StyledTableColumn>{mapStage(item.stage)}</StyledTableColumn>
                <StyledTableColumn>{item.totalPoints || '0'}</StyledTableColumn>
              </StyledTableRow>
            ))}

            {!data?.topFightsPoints?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Lutas mais rápidas</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Fase</StyledTableColumn>
            <StyledTableColumn>Atletas</StyledTableColumn>
            <StyledTableColumn>Tempo</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.fightFaster?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumn>
                  {item?.eventAreaCategory?.categoryName || '--'}
                </StyledTableColumn>
                <StyledTableColumn>{mapStage(item.stage)}</StyledTableColumn>
                <StyledTableColumn>
                  {item.athletes
                    .map((item: any) => item.nameAthlete || '-')
                    .join(' x ')}
                </StyledTableColumn>
                <StyledTableColumn>
                  {item.minTimeFight
                    ? formatSecondsToMinutesAndSeconds(item.minTimeFight)
                    : '0s'}
                </StyledTableColumn>
              </StyledTableRow>
            ))}

            {!data?.fightFaster?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Atletas que mais pontuaram</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Atleta</StyledTableColumn>
            <StyledTableColumn>Pontos</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.athletesMorePoints?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumn>
                  {item.categoryName || '--'}
                </StyledTableColumn>
                <StyledTableColumn>
                  {item.nameAthlete || '--'}
                </StyledTableColumn>
                <StyledTableColumn>{item.totalPoints || '0'}</StyledTableColumn>
              </StyledTableRow>
            ))}

            {!data?.athletesMorePoints?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Atletas desclassificados</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Atleta</StyledTableColumn>
            <StyledTableColumn>Motivo</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.athletesDesclassifiedList?.map(
              (item: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableColumn>
                    {item?.eventAreaCategoryFight?.eventAreaCategory
                      ?.categoryName || '--'}
                  </StyledTableColumn>
                  <StyledTableColumn>
                    {item.nameAthlete || '--'}
                  </StyledTableColumn>
                  <StyledTableColumn>{item.reason || '--'}</StyledTableColumn>
                </StyledTableRow>
              )
            )}

            {!data?.athletesDesclassifiedList?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>
    </StyledRoot>
  );
};

export default StatisticsReports;

export const compareStrings = (str1: string, str2: string): boolean => {
  const normalize = (str: string) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  return normalize(str1).includes(normalize(str2));
};

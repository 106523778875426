import React, { ReactElement, useEffect, useState } from 'react';

import { Avatar } from '@mui/material';
import {
  StyledContentName,
  StyledContentNumber,
  StyledContentScore,
  StyledContentTime,
  StyledFightOne,
  StyledFightTwo,
  StyledLeft,
  StyledName,
  StyledRight,
  StyledRoot,
  StyledTeam,
} from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import useEvent from '../../../recoil/hooks/event';
import Presentation from './Presentation';

const defaultScore = {
  athleteOne: {
    id: '',
    points: 0,
    advantages: 0,
    faults: 0,
  },
  athleteTwo: {
    id: '',
    points: 0,
    advantages: 0,
    faults: 0,
  },
};

const ScoreboardShare: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const { event } = useEvent();

  const [time, setTime] = useState(0);

  const [scoreDetails, setScoreDetails] = useState<any>(null);

  const [boardId, setBoardId] = useState('');

  const [withoutWinner, setWithoutWinner] = useState('');

  const [winner, setWinner] = useState<any>(null);

  const [score, setScore] = useState(defaultScore);

  useEffect(() => {
    const updateTime = () => {
      const boardId = localStorage.getItem(`@ilutas:board`);
      const newTime = localStorage.getItem(`@ilutas:${boardId}-time`);
      const score = localStorage.getItem(`@ilutas:${boardId}-score`);
      const winner = localStorage.getItem(`@ilutas:${boardId}-winner`);

      const withoutWinner = localStorage.getItem(
        `@ilutas:${boardId}-withoutWinner`
      );
      const scoreDetails = localStorage.getItem(
        `@ilutas:${boardId}-scoreDetails`
      );

      setWithoutWinner(withoutWinner || '');

      setWinner(winner ? JSON.parse(winner) : null);
      setTime(Number(newTime));
      setScore(score ? JSON.parse(score) : defaultScore);
      setBoardId(boardId || '');
      setScoreDetails(scoreDetails ? JSON.parse(scoreDetails) : null);
    };

    updateTime();

    const interval = setInterval(() => {
      updateTime();
    }, 900);

    return () => clearInterval(interval);
  }, []);

  const mapStage = (stage: any): string => {
    const stageOrder: any = {
      'eighth-final': 'Oitavas de final',
      'quarter-final': 'Quartas de final',
      semifinal: 'Semifinal',
      bronze: 'Terceiro lugar',
      final: 'Final',
    };

    return stageOrder[stage] || stage;
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const getAthlete = (athletes: any[], order: number): any => {
    return athletes?.find((item) => item.order === order);
  };

  if (!boardId) {
    return (
      <div
        style={{
          background: '#000',
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 32,
        }}
      >
        <div
          style={{
            fontSize: 64,
            fontWeight: '600',
            color: '#fff',
            maxWidth: '90%',
            textAlign: 'center',
          }}
        >
          {event?.name}
        </div>
        <img
          src={authenticate?.user?.imageEvent || '/images/ilutas.png'}
          style={{
            maxWidth: '50%',
            maxHeight: '50%',
            borderRadius: '50%',
            border: '2px solid #fff',
          }}
        />
      </div>
    );
  }

  if (winner) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: winner.order === 0 ? '#2B4755' : '#fff',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: 64,
              fontWeight: 600,
              color: winner.order === 0 ? '#fff' : '#2B4755',
            }}
          >
            Ganhador 🏆
          </div>
          <div
            style={{
              padding: 24,
              boxSizing: 'border-box',
              color: winner.order === 0 ? '#fff' : '#2B4755',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 24,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Avatar
                alt={winner?.nameAthlete}
                src={winner?.photo}
                sx={{ width: 150, height: 150 }}
              />
              <StyledContentName>
                <StyledName>{winner?.nameAthlete}</StyledName>
                <StyledTeam>{winner?.gym}</StyledTeam>
              </StyledContentName>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (withoutWinner) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#2B4755',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: 64,
              fontWeight: 600,
              color: '#fff',
            }}
          >
            Resultado
          </div>
          <div
            style={{
              padding: 24,
              boxSizing: 'border-box',
              color: '#fff',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 24,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StyledContentName>
                <StyledName>Não houve ganhador</StyledName>
              </StyledContentName>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (scoreDetails?.type === 'presentation') {
    return <Presentation scoreDetails={scoreDetails} />;
  }

  return (
    <React.Fragment>
      <StyledRoot>
        <StyledFightOne>
          <StyledLeft>
            <Avatar
              alt={getAthlete(scoreDetails?.athletes, 0)?.nameAthlete}
              src={getAthlete(scoreDetails?.athletes, 0)?.photo}
              sx={{ width: 150, height: 150 }}
            />
            <StyledContentName>
              <StyledName>
                {getAthlete(scoreDetails?.athletes, 0)?.nameAthlete}
              </StyledName>
              <StyledTeam>
                {getAthlete(scoreDetails?.athletes, 0)?.gym}
              </StyledTeam>
            </StyledContentName>
          </StyledLeft>
          <StyledRight>
            <StyledContentScore>
              <StyledContentNumber color="#4caf50">
                {score?.athleteOne?.points || '0'}
              </StyledContentNumber>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#f1c40f">
                {score?.athleteOne?.advantages || '0'}
              </StyledContentNumber>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#b71c1c">
                {score?.athleteOne?.faults || '0'}
              </StyledContentNumber>
            </StyledContentScore>
          </StyledRight>
        </StyledFightOne>

        <StyledFightTwo>
          <StyledLeft>
            <Avatar
              alt={getAthlete(scoreDetails?.athletes, 1)?.nameAthlete}
              src={getAthlete(scoreDetails?.athletes, 1)?.photo}
              sx={{ width: 150, height: 150 }}
            />
            <StyledContentName>
              <StyledName>
                {getAthlete(scoreDetails?.athletes, 1)?.nameAthlete}
              </StyledName>
              <StyledTeam>
                {getAthlete(scoreDetails?.athletes, 1)?.gym}
              </StyledTeam>
            </StyledContentName>
          </StyledLeft>
          <StyledRight>
            <StyledContentScore>
              <StyledContentNumber color="#4caf50">
                {score?.athleteTwo.points || '0'}
              </StyledContentNumber>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#f1c40f">
                {score?.athleteTwo.advantages || '0'}
              </StyledContentNumber>
            </StyledContentScore>

            <StyledContentScore>
              <StyledContentNumber color="#b71c1c">
                {score?.athleteTwo.faults || '0'}
              </StyledContentNumber>
            </StyledContentScore>
          </StyledRight>
        </StyledFightTwo>

        <StyledContentTime>
          <div
            style={{
              flex: 1,
              padding: 12,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 120,
                fontWeight: 600,
                color: '#FFF',
              }}
            >
              {formatTime(time)}
            </div>
          </div>
          <div
            style={{
              width: '50%',
              flex: 1,
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 24,
            }}
          >
            <div style={{ fontSize: 48 }}>
              {mapStage(scoreDetails?.stage || '')}
            </div>
            <div
              style={{
                fontSize: 36,
                width: '100%',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {scoreDetails?.eventAreaCategory?.categoryName || ''}
            </div>
          </div>
        </StyledContentTime>
      </StyledRoot>
    </React.Fragment>
  );
};

export default ScoreboardShare;

import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '0px 24px 24px 24px',
  boxSizing: 'border-box',
});

export const StyledCard = styled(Box)({
  width: 200,
  minWidth: 200,
  borderRadius: 8,
  padding: 8,
  boxSizing: 'border-box',
  boxShadow: '0 0 8px 0px rgba(0, 0, 0, 0.25)',
});

export const StyledContentCards = styled(Box)({
  marginTop: 16,
  marginBottom: 16,
  display: 'flex',
  gap: 16,
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const StyledCount = styled(Box)({
  fontSize: 26,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 16,
  boxSizing: 'border-box',
  color: '#083061',
});

export const StyledTable = styled(Box)({
  minWidth: 200,
  borderRadius: 8,
  padding: 8,
  boxSizing: 'border-box',
  boxShadow: '0 0 8px 0px rgba(0, 0, 0, 0.25)',
});

export const StyledTableHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0',
  boxSizing: 'border-box',
  fontWeight: 'bold',
});

export const StyledTableBody = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  maxHeight: 350,
  overflowY: 'auto',
});

export const StyledTableRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '4px 0px',
  boxSizing: 'border-box',

  '&:nth-child(odd)': {
    background: '#eee',
    borderRadius: 8,
  },
});

export const StyledTableColumn = styled(Box)({
  display: 'flex',
  flex: 1,
  textAlign: 'center',
  justifyContent: 'center',
});

export const StyledTitle = styled(Box)({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  fontSize: 18,
  padding: 28,
  color: '#555',
  boxSizing: 'border-box',
});

export const StyledNotFound = styled(Box)({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 16,
  padding: 24,
  background: '#eee',
  borderRadius: 8,
  color: '#555',
  boxSizing: 'border-box',
});

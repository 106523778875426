import * as yup from 'yup';

export const initialFilterFormValues = {
  category: '',
  track: '',
  weight: '',
  gender: '',
  typeCategory: '',

  typeCategoryMultiple: [] as string[],
  categoryMultiple: [] as string[],
  trackMultiple: [] as string[],
  weightMultiple: [] as string[],
  genderMultiple: [] as string[],
};

export interface FilterFormProps {
  category: string;
  track: string;
  weight: string;
  gender: string;
  typeCategory: string;
  typeCategoryMultiple: string[];
  categoryMultiple: string[];
  trackMultiple: string[];
  weightMultiple: string[];
  genderMultiple: string[];
}

export const validationSchema = yup.object({});

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import {
  getEventAreasFights,
  getEventCategories,
  getReasonDesclassifiedRequest,
} from '../../../api/events';

import ModalInvalid from './ModalInvalid';
import NoAreas from '../../../components/NoAreas';
import CheckinShareAreas from '../../../components/CheckinShareAreas';
import Loading from '../../../components/Loading';

const CheckinShare: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { eventId } = useParams();

  const [event, setEvent] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [eventAreas, setEventAreas] = useState<any>([]);

  const [reasonDesclassified, setReasonDesclassified] = useState<any>([]);

  const [isModalInvalid, setModalInvalid] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAreas, setLoadingAreas] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
        setCategories(data?.categories || []);

        // const requiredFieldsEvent = ['numberAreas', 'startTimeFight', '']

        if (!data?.event?.numberAreas) {
          setModalInvalid(true);
        }
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getReasonDesclassified = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonDesclassifiedRequest(authenticate.token);

        setReasonDesclassified(data?.data || []);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  }, [authenticate.token]);

  useEffect(() => {
    getReasonDesclassified();
  }, [getReasonDesclassified]);

  const getEventAreasFightsData = useCallback(
    async (defaultLoading = true) => {
      try {
        setLoadingAreas(defaultLoading);
        if (authenticate.token && eventId) {
          const data = await getEventAreasFights(authenticate.token, eventId);

          setEventAreas(data?.areas || []);
        }
      } catch (error) {
        toast(
          'Não foi possível executar essa ação. Por favor, tente novamente!',
          { type: 'error' }
        );
      } finally {
        setLoadingAreas(false);
      }
    },
    [authenticate.token, eventId]
  );

  useEffect(() => {
    getEventAreasFightsData();

    const intervalId = setInterval(() => {
      getEventAreasFightsData(false);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [getEventAreasFightsData]);

  const isLoadingGeneral = useMemo(() => {
    return isLoading || isLoadingAreas;
  }, [isLoading, isLoadingAreas]);

  return (
    <React.Fragment>
      <ModalInvalid
        open={isModalInvalid}
        cause={[]}
        handleClose={() => navigate(`/dash/events/${eventId}/home?edit=true`)}
        message="Alguns campos do evento não estão configurados. Por favor, finaliza a configuração primeiro!"
      />

      <StyledRoot>
        {isLoading && <Loading />}

        {!isLoadingGeneral && !!event && !eventAreas?.length && (
          <NoAreas
            title="Checkin"
            message="Configure as áreas do evento para começar!"
            handleStart={() =>
              navigate(`/dash/events/${eventId}/areas?isEditing=true`)
            }
          />
        )}

        {!isLoadingGeneral && !!event && !!eventAreas?.length && (
          <div
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            <CheckinShareAreas
              categories={categories}
              eventAreas={eventAreas}
              areas={event?.numberAreas}
              numberDays={event?.numberDaysFight}
              reasonDesclassified={reasonDesclassified}
            />
          </div>
        )}
      </StyledRoot>
    </React.Fragment>
  );
};

export default CheckinShare;

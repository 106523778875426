import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#083061',
    },
  },
  typography: {
    fontFamily: ['Lato'].join(','),
    button: {
      fontFamily: ['Lato'].join(','),
    },
    allVariants: {
      fontFamily: ['Lato'].join(','),
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },
});

/* eslint-disable no-plusplus */
import React, { ReactElement, useMemo } from 'react';

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useFormik } from 'formik';
import { initialFilterFormValues } from './schema';

interface FilterOrganizeAreaV2Props {
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  categories: any[];
  hasFiltersApplied: boolean;
  isAutoOrganize?: boolean;
  handleSubmit: (values: any) => void;
}

const FilterOrganizeAreaV2: React.FC<FilterOrganizeAreaV2Props> = ({
  categories,
  filtersData,
  hasFiltersApplied,
  isAutoOrganize,
  handleSubmit,
}): ReactElement => {
  const data = useMemo(() => {
    const uniqueCategories = filtersData?.category || [];
    const uniqueTack = filtersData?.track || [];
    const uniqueWeight = filtersData?.weight || [];

    const allGender = categories
      .map((item: any) => item.genderName)
      .filter((item: any) => !!item && item !== 'Ambos');
    const uniqueGender = [...new Set(allGender)];

    const allTypes = categories
      .map((item: any) => item.typeCategoryName)
      .filter((item: any) => !!item);
    const uniqueTypes = [...new Set(allTypes)];

    return {
      uniqueCategories,
      uniqueTack,
      uniqueWeight,
      uniqueGender,
      uniqueTypes,
    };
  }, [categories, filtersData]);

  const onSubmit = (values: any) => {
    handleSubmit(values);
  };

  const formik = useFormik({
    initialValues: initialFilterFormValues,
    enableReinitialize: true,
    onSubmit,
  });

  const resetForm = () => {
    formik.setValues(initialFilterFormValues);
    handleSubmit(initialFilterFormValues);
  };

  const handleChange = (key: string) => (event: any) => {
    const value = event?.target?.value || '';
    formik.setFieldValue(
      key,
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: 16,
        gap: 16,
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          gap: 16,
          boxSizing: 'border-box',
        }}
      >
        <div style={{ width: '100%' }}>
          <FormControl fullWidth>
            <InputLabel>Tipo da categoria</InputLabel>
            <Select
              multiple
              fullWidth
              value={formik.values.typeCategoryMultiple}
              onChange={handleChange('typeCategoryMultiple')}
              input={<OutlinedInput label="Tipo da categoria" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {data.uniqueTypes?.map((typeCategory: any) => (
                <MenuItem key={typeCategory} value={typeCategory}>
                  <Checkbox
                    checked={formik.values?.typeCategoryMultiple?.includes(
                      typeCategory
                    )}
                  />
                  <ListItemText primary={typeCategory} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <FormControl fullWidth>
          <InputLabel>Categoria</InputLabel>
          <Select
            multiple
            fullWidth
            value={formik.values.categoryMultiple}
            onChange={handleChange('categoryMultiple')}
            input={<OutlinedInput label="Categoria" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {data.uniqueCategories?.map((category: any) => (
              <MenuItem key={category} value={category}>
                <Checkbox
                  checked={formik.values?.categoryMultiple?.includes(category)}
                />
                <ListItemText primary={category} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Graduação</InputLabel>
          <Select
            multiple
            fullWidth
            value={formik.values.trackMultiple}
            onChange={handleChange('trackMultiple')}
            input={<OutlinedInput label="Graduação" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {data.uniqueTack?.map((track: any) => (
              <MenuItem key={track} value={track}>
                <Checkbox
                  checked={formik.values?.trackMultiple?.includes(track)}
                />
                <ListItemText primary={track} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ width: '100%' }} hidden={isAutoOrganize}>
          <FormControl fullWidth>
            <InputLabel>Peso</InputLabel>
            <Select
              multiple
              fullWidth
              value={formik.values.weightMultiple}
              onChange={handleChange('weightMultiple')}
              input={<OutlinedInput label="Peso" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {data.uniqueWeight?.map((weight: any) => (
                <MenuItem key={weight} value={weight}>
                  <Checkbox
                    checked={formik.values?.weightMultiple?.includes(weight)}
                  />
                  <ListItemText primary={weight} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div style={{ width: '100%' }} hidden={isAutoOrganize}>
          <FormControl fullWidth>
            <InputLabel>Sexo</InputLabel>
            <Select
              multiple
              fullWidth
              value={formik.values.genderMultiple}
              onChange={handleChange('genderMultiple')}
              input={<OutlinedInput label="Sexo" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {data.uniqueGender?.map((gender: any) => (
                <MenuItem key={gender} value={gender}>
                  <Checkbox
                    checked={formik.values?.genderMultiple?.includes(gender)}
                  />
                  <ListItemText primary={gender} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
        {hasFiltersApplied && (
          <Button
            color="error"
            style={{
              letterSpacing: 1,
              fontSize: 14,
            }}
            onClick={() => resetForm()}
          >
            Limpar filtros
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          style={{
            letterSpacing: 1,
            fontSize: 14,
          }}
        >
          Filtrar
        </Button>
      </div>
    </form>
  );
};

export default FilterOrganizeAreaV2;

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { StyledItemCategory } from '../styles';
import {
  differenceInMinutesCeil,
  formatMinutesToTime,
  formatOnlyHour,
} from '../../../utils/date';
import { compareStrings } from '../../../utils/string';

interface AreasCategoryProps {
  eventAreas?: any[];
  numberDays: number;
  filters: any;
  daySelected: string;
  search?: string;
  showInfo?: boolean;
  redirect?: (item: any) => void;
}

const AreasCategory: React.FC<AreasCategoryProps> = ({
  eventAreas,
  filters,
  numberDays,
  daySelected,
  search,
  showInfo = true,
  redirect,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [days, setDays] = useState<number[]>([]);

  const applySearch = useCallback(
    (items: any[]) => {
      if (!search) {
        return items;
      }

      return items.filter((item) => {
        let hasItem = false;

        const hasName = compareStrings(item?.categoryName, search);

        if (hasName) {
          return true;
        }

        for (const fight of item.fights) {
          for (const athlete of fight?.athletes) {
            if (compareStrings(athlete?.nameAthlete, search)) {
              hasItem = true;
              break;
            }
          }
        }

        return hasItem;
      });
    },
    [search]
  );

  useEffect(() => {
    if (eventAreas?.length) {
      const newDays: number[] = [];
      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);
        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          name: item.name,
          categories: item.categories?.length
            ? item.categories
                .map((category: any) => {
                  return {
                    ...category,
                    fights: category.fights,
                    order: category.order,
                  };
                })
                .sort((a: any, b: any) => {
                  return a.order - b.order;
                })
            : [],
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
    }
  }, [eventAreas, numberDays]);

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.genderMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (filters.category && item.categoryName !== filters.category) {
            avaliable = false;
          }
          if (filters.gender && item.genderName !== filters.gender) {
            avaliable = false;
          }
          if (filters.weight && item.weightName !== filters.weight) {
            avaliable = false;
          }
          if (filters.track && item.trackName !== filters.track) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item.typeCategoryName !== filters.typeCategory
          ) {
            avaliable = false;
          }

          if (
            filters.typeCategoryMultiple?.length &&
            !filters.typeCategoryMultiple.includes(item.typeCategoryName)
          ) {
            avaliable = false;
          }

          if (
            filters.categoryMultiple?.length &&
            !filters.categoryMultiple.includes(item.categoryName)
          ) {
            avaliable = false;
          }

          if (
            filters.genderMultiple?.length &&
            !filters.genderMultiple.includes(item.genderName)
          ) {
            avaliable = false;
          }

          if (
            filters.weightMultiple?.length &&
            !filters.weightMultiple.includes(item.weightName)
          ) {
            avaliable = false;
          }

          if (
            filters.trackMultiple?.length &&
            !filters.trackMultiple.includes(item.trackName)
          ) {
            avaliable = false;
          }

          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const formatName = (item: any): string => {
    return item.descriptionCategory || item.categoryName;
  };

  const countNumberFight = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return (
          Number(prev) +
          (current.generateKey === 'N' ? 0 : Number(current.numberFights))
        );
      },
      [0]
    );
  };

  const countNumberAthletes = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return (
          Number(prev) +
          (current.generateKey === 'S' ? Number(current.numberAthletes) : 0)
        );
      },
      [0]
    );
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const sortArrayV2 = (array: any[]): any[] => {
    if (!array.length) return [];
    return array.sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();

      if (timeA !== timeB) {
        return timeA - timeB;
      }

      return a.order - b.order;
    });
  };

  const getFistDate = (categories: any[]): string => {
    if (!categories.length) {
      return '--';
    }
    const firstCategory = categories.find((item) => item.order === 0);

    if (!firstCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);

    const firstFight = fisrtFightsSorted[0];

    if (!firstFight) {
      return '';
    }

    return formatOnlyHour(firstFight.date);
  };

  const getFinalEta = (categories: any[]): string => {
    if (!categories.length) {
      return 'xx:xx';
    }
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!lastCategory) {
      return '';
    }

    const fightsSorted = sortArrayV2(lastCategory.fights);

    if (!fightsSorted.length) {
      return '';
    }

    return formatOnlyHour(fightsSorted[fightsSorted.length - 1].date);
  };

  const getTimeTotal = (categories: any[]): string => {
    if (!categories.length) {
      return '--';
    }
    const firstCategory = categories.find((item) => item.order === 0);
    const lastCategory = categories.find(
      (item) => item.order === categories.length - 1
    );

    if (!firstCategory || !lastCategory) {
      return '';
    }

    const fisrtFightsSorted = sortArrayV2(firstCategory.fights);
    const lastFightsSorted = sortArrayV2(lastCategory.fights);

    const firstFight = fisrtFightsSorted[0];
    const lastFight = lastFightsSorted[lastFightsSorted.length - 1];

    if (!firstFight || !lastFight) {
      return '';
    }

    const diff = differenceInMinutesCeil(lastFight.date, firstFight.date);

    return `${formatMinutesToTime(diff)}`;
  };

  const getFisrtFightDate = (fights: any[]): string => {
    const fightsSorted = sortArrayV2(fights);
    return formatOnlyHour(fightsSorted[0].date);
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  const renderItems = useCallback(
    (item: any) => {
      const itemsSearch = applySearch(item.categories);

      const itemsFiltered = applyFilters(itemsSearch);

      return itemsFiltered;
    },
    [applyFilters, applySearch]
  );

  return (
    <React.Fragment>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          {renderObjectDay.map((day, index) => (
            <React.Fragment key={`${index}-${day}`}>
              <div
                style={{
                  width: '100%',
                  overflowX: 'auto',
                  display: 'flex',
                  gap: 18,
                  paddingBottom: 16,
                  boxSizing: 'border-box',
                }}
              >
                {filterByDay(areasObject, day).map((item, index) => (
                  <div key={index} style={{ minWidth: 336, width: 336 }}>
                    <div>{item.name}</div>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: 8,
                        width: 336,
                        minHeight: 100,
                        marginTop: 12,
                        boxSizing: 'border-box',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

                        height: 400,
                        overflowY: 'auto',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: 300,
                          fontSize: 12,
                          padding: 10,
                          boxSizing: 'border-box',
                        }}
                      >
                        <div
                          style={{
                            width: 220,
                            boxSizing: 'border-box',
                          }}
                        >
                          Categoria
                        </div>
                        <div
                          style={{
                            width: 40,
                            textAlign: 'center',
                            boxSizing: 'border-box',
                          }}
                        >
                          Lutas
                        </div>
                        <div
                          style={{
                            width: 40,
                            textAlign: 'center',
                            boxSizing: 'border-box',
                          }}
                        >
                          HEL
                        </div>
                      </div>
                      {renderItems(item).map(
                        (item: any, indexCategory: number) => (
                          <StyledItemCategory
                            key={`${index}-${indexCategory}`}
                            style={{}}
                            onClick={() => redirect?.(item)}
                          >
                            <div style={{ width: 220 }}>
                              <div>{formatName(item)}</div>
                              {item.generateKey === 'N' && (
                                <div style={{ color: '#aaa', fontSize: 14 }}>
                                  Apresentação
                                </div>
                              )}
                            </div>

                            <div style={{ width: 40, textAlign: 'center' }}>
                              {item.generateKey === 'N'
                                ? '-'
                                : item.numberFights}
                            </div>
                            <div style={{ width: 40, textAlign: 'center' }}>
                              {getFisrtFightDate(item.fights)}
                            </div>
                          </StyledItemCategory>
                        )
                      )}
                    </div>
                    {showInfo && (
                      <div
                        style={{
                          paddingTop: 12,
                          boxSizing: 'border-box',
                        }}
                      >
                        <div>Nº de categorias: {item.categories.length}</div>
                        {countNumberAthletes(item.categories) > 0 && (
                          <div>
                            Nº de atletas:{' '}
                            {countNumberAthletes(item.categories)}
                          </div>
                        )}
                        <div>
                          Nº de lutas: {countNumberFight(item.categories)}
                        </div>
                        <div>
                          Horário inicial: {getFistDate(item.categories)}
                        </div>
                        <div>Tempo total: {getTimeTotal(item.categories)}</div>
                        <div>
                          Estimativa final: {getFinalEta(item.categories)}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AreasCategory;
